import usei18n from "@app-i18n/index";
import xlfSource from "@app-locales/index.xlf";
import supportedLanguages from "@app-locales/languages.json";
import { resolveAny } from "@app-rest/index";
import useCompanyStore from "@app-store/company";
import { ITranslation } from "@qamf/xliff-loader";
import { localize as VeeValidateLocalize } from "vee-validate";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import { datesDictionary, importXlf, LanguageIso, validationDictionary } from "@/i18n/statics";

import store from ".";

type SupportedLanguagesMap = typeof supportedLanguages;
@Module class LangStoreFactory extends VuexModule {
	readonly defaultIsoCode: LanguageIso = "en-US";
	isoCode: LanguageIso | null = null;
	language: SupportedLanguagesMap[LanguageIso] | null = null;
	languages: SupportedLanguagesMap = supportedLanguages;
	libLangMap = new Map<string, string>();
	libDictionary = new Map<string, any>();

	private downloadXlf(): Promise<ITranslation> {
		return new Promise(async resolve => {
			usei18n().xlfObjectSource.value = xlfSource;
			const exitResolve = (xlf: ITranslation) => {
				usei18n().xlfObject.value = xlf;
				resolve(xlf);
			};

			const lang = await importXlf(this.isoCode ?? this.defaultIsoCode);
			exitResolve(lang.default);
		});
	}

	private async dateFnsLanguage() {
		// eslint-disable-next-line no-new
		new Promise<void>(async resolve => {
			const langToSet = String(this.isoCode ?? this.defaultIsoCode);
			const parts = langToSet.split("-");
			const current = this.libLangMap.get("date-fns");

			if (current === parts[0]) return resolve();

			let localeToSet = String(this.isoCode ?? this.defaultIsoCode);
			if (current === localeToSet) return resolve();
			if (!(localeToSet in datesDictionary))
				localeToSet = "en-US";
			const [dictionary] = await resolveAny(datesDictionary[localeToSet]());
			this.libLangMap.set("date-fns", localeToSet);
			this.libDictionary.set("date-fns", (dictionary as any).default);

			resolve();
		});
	}

	private async veeValidateLanguage() {
		// eslint-disable-next-line no-new
		new Promise<void>(async resolve => {
			const langToSet = String(this.isoCode ?? this.defaultIsoCode);
			const parts = langToSet.split("-");
			const current = this.libLangMap.get("vee-validate");

			if (current === parts[0]) return resolve();

			let lang = parts[0] + "";
			if (!(lang in validationDictionary))
				lang = "en-US";
			const [dictionary] = await resolveAny(validationDictionary[langToSet]());
			VeeValidateLocalize(lang, (dictionary as any));
			this.libLangMap.set("vee-validate", lang);

			resolve();
		});
	}

	@Mutation setCurrentIsoLang(isoLang: LanguageIso) {
		this.isoCode = isoLang;
	}

	@Mutation setCurrentLangData(data: SupportedLanguagesMap[LanguageIso] | null) {
		this.language = data;
	}

	@Action async ensureLanguage() {
		const CompanyStore = useCompanyStore();
		const language = (CompanyStore.Language && CompanyStore.Language !== ""
			? CompanyStore.Language
			: this.defaultIsoCode) as LanguageIso;

		if (this.isoCode && this.isoCode === language)
			return;

		await this.syncLanguage(language);
	}

	@Action async syncLanguage(targetIsoCode: LanguageIso) {
		let langToSet = String(this.defaultIsoCode) as LanguageIso;
		if (targetIsoCode.toLowerCase() !== this.defaultIsoCode.toLowerCase()) {
			const availableLangs = this.languages ? Object.keys(this.languages) : [];
			const hasLang = availableLangs.includes(targetIsoCode);

			if (hasLang)
				langToSet = targetIsoCode;
			else {
				const langParts = targetIsoCode.split("-");
				const matched = availableLangs.find(l => l.split("-")[0] === langParts[0]) as LanguageIso | undefined;
				langToSet = matched ?? this.defaultIsoCode;
			}
		}

		if (langToSet && this.languages && this.languages[langToSet]) {
			this.setCurrentIsoLang(langToSet);
			await Promise.all([
				this.downloadXlf(),
				this.dateFnsLanguage(),
				this.veeValidateLanguage()
			]);
			const langData = this.languages[langToSet];
			const html = document.documentElement;
			html.setAttribute("lang", langToSet);
			html.setAttribute("dir", langData.textDirection);
			this.setCurrentLangData(langData);
		}
	}
}

const moduleName = "lang";

let LangStore: LangStoreFactory | null;

function useLangStore() {
	if (LangStore) return LangStore;

	const mod = LangStore = new LangStoreFactory({ store, name: moduleName });

	return mod;
}

export default useLangStore;

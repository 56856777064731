import { IPolicyTermMenu, PolicyTermType } from "@app-rest/common.interfaces";
import CompanyRestFactory from "@app-rest/company";
import { ICompany, ICompanyLocationGroup, IPrivacyData } from "@app-rest/company.interfaces";
import useLangStore from "@app-store/lang";
import { flatten, flow, map } from "lodash-es";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

@Module class CompanyStoreFactory extends VuexModule implements ICompany {
	Id = 0;
	Name = "";
	Language = "";
	locations: ICompanyLocationGroup[] = [];
	restClient: CompanyRestFactory | null = null;
	policies: IPolicyTermMenu[] = [];
	privacyData: IPrivacyData[] = [];

	get plainLocations() {
		return flow(
			(locations: ICompanyLocationGroup[]) => map(locations, l => l.GroupItems),
			systems => flatten(systems)
		)(this.locations);
	}

	get justOneLocation() {
		// FIXME: waiting for user story about Locations Groups Setup (QPortal)
		return true;
		// const plain: ICompanyLocation[] = this.plainLocations;
		// return plain.length === 1;
	}

	@Mutation setCompany(data: ICompany | null) {
		this.Id = data?.Id ?? 0;
		this.Name = data?.Name ?? "";
		this.Language = data?.Language ?? useLangStore().defaultIsoCode;
	}

	@Mutation setLocations(locations: ICompanyLocationGroup[] | null) {
		this.locations = locations ?? [];
	}

	@Mutation setPolicies(policies: IPolicyTermMenu[]) {
		this.policies = policies;
	}

	@Mutation setPrivacyData(data: IPrivacyData[]) {
		this.privacyData = data;
	}

	@Mutation setRestClient(restClient: CompanyRestFactory) {
		this.restClient = restClient;
	}

	@Action ensureRestClient() {
		if (!this.restClient)
			this.setRestClient(new CompanyRestFactory());
		return Promise.resolve(this.restClient as CompanyRestFactory);
	}

	@Action async ensureCompany(id: number) {
		if (this.Id && this.Id === id) return;
		const restClient = await this.ensureRestClient();
		const response = await restClient.info(id);
		this.setCompany(response.data);
	}

	@Action async ensureLocations() {
		if (!this.Id || this.locations.length > 0) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.locations(this.Id);
		this.setLocations(response.data);
	}

	@Action async ensurePolicies() {
		if (!this.Id || (this.policies && this.policies.length !== 0))
			return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.getPolicyList(this.Id);

		// FIXME: remove after BE clean dirty data US #27232
		const onlyPrivacyPolicy = response?.data?.filter(term => term.Type !== PolicyTermType.CookiePolicy
				&& term.Type !== PolicyTermType.TermsAndConditions
				&& term.Type !== PolicyTermType.TermsOfUse
				&& term.Type !== PolicyTermType.KioskPlayNow);

		this.setPolicies(onlyPrivacyPolicy ?? []);
	}

	@Action async ensurePrivacyData(id: number) {
		if (this.privacyData.length) return;
		const restClient = await this.ensureRestClient();
		const response = await restClient.privacy(id);
		this.setPrivacyData(response?.data ?? []);
	}
}

const moduleName = "company";

let CompanyStore: CompanyStoreFactory | null;

function useCompanyStore() {
	if (CompanyStore) return CompanyStore;

	const mod = CompanyStore = new CompanyStoreFactory({ store, name: moduleName });

	return mod;
}

export default useCompanyStore;

import { IReservationFormData, IReservationFormDataBowlers } from "@app-components/reservation-form/reservation-form";
import type { IPlayersQuantity } from "@app-rest/systems.interfaces";
import router from "@app-router/index";
import useSystemStore from "@app-store/system";
import { Route } from "vue-router";

import { dateTimeKey, stringToDateUTC } from "./dates";

export function parseQueryStringFormData(route?: Route) {
	if (!route)
		route = router.currentRoute;
	const formData: IReservationFormData = { datetime: null, bowlers: [] };

	const datetime = route.query.q_datetime as string | undefined;
	formData.datetime = datetime ? stringToDateUTC(datetime) ?? null : null;

	const bowlers = route.query.q_bowlers as string | undefined;
	formData.bowlers = bowlers?.split(",").map(bowlerStr => {
		const parts = bowlerStr.split("-");
		const id = parts[0];
		if (!id || isNaN(+id)) return false;

		const q = parts[1];
		const bTypeFromQs: IReservationFormDataBowlers = {
			Id: +id,
			Quantity: !q || isNaN(+q) ? 1 : +q
		};
		const playerTypes = useSystemStore().playerTypes;
		const bType = playerTypes?.Types.find(t => t.Id === +id);
		if (!bType) return false;

		return {
			...bType,
			...bTypeFromQs
		};
	}).filter(Boolean) as IPlayersQuantity[] ?? [];
	return formData;
}
export function getQueryStringFormData(formData: IReservationFormData) {
	const query = { ...router.currentRoute.query };

	delete query.q_datetime;
	if (formData.datetime)
		query.q_datetime = dateTimeKey(formData.datetime);

	delete query.q_bowlers;
	if (formData.bowlers.length)
		query.q_bowlers = formData.bowlers.map(b => `${b.Id}-${b.Quantity}`).join(",");

	return query;
}
export function clearedQueryStringFormData(route?: Route) {
	if (!route)
		route = router.currentRoute;
	const query = { ...route.query };
	Object.keys(query).forEach(key => {
		if (key.indexOf("q_") === 0)
			delete query[key];
	});
	return query;
}

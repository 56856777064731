import NavButton from "@app-components/nav-button/nav-button.vue";
import Price from "@app-components/price/price.vue";
import Quantifier from "@app-components/quantifier/quantifier.vue";
import { useRoute } from "@app-composables/router";
import usei18n from "@app-i18n/index";
import { extrasPage, foodBeveragePage, footwearsPage, offersPage } from "@app-router/routes/reservations";
import useCartStore, { CartItemCategory, ICartItem } from "@app-store/cart";
import useReservationStore from "@app-store/reservation";
import useReservationFluxStore from "@app-store/reservation-flux";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import { modalConfirm } from "@app-utilities/modals";
import { BButton } from "bootstrap-vue";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
	name: "CartSummary",
	components: {
		BButton,
		NavButton,
		Quantifier,
		Price
	},
	props: {
		showTotal: { type: Boolean, default: true },
		showModal: { type: Boolean, default: true }
	},
	emits: ["change"],
	setup(props, { emit }) {
		const quantifiers = ref<typeof Quantifier [] | null>([]);
		const categories = computed(() => {
			return useCartStore().categories;
		});
		const systemId = computed(() => {
			return useReservationStore().systemId;
		});
		const playersLabel = computed(() => {
			return useReservationStore().bowlers
				.map((b) => {
					if (!b.Quantity) return false;
					return `${b.Quantity} ${b.Name}`;
				})
				.filter(Boolean)
				.join(", ");
		});
		const totalWithoutTaxes = computed(() => {
			return useCartStore().totalWithoutTaxes;
		});
		const minimumQuantity = ref(0);
		const getItemsInCategory = (category: CartItemCategory) => {
			return useCartStore().getItemsInCategory(category);
		};
		const getTotalInCategory = (category: CartItemCategory) => {
			return useCartStore().getTotalInCategory(category);
		};
		const getLinkLabel = (category: CartItemCategory) => {
			return category === "bowling-experience"
				? usei18n().translateKey("label_bowling")
				: category === "footwears"
					? usei18n().translateKey("shoes_and_socks")
					: category === "food-beverage"
						? usei18n().translateKey("food_and_beverage")
						: category === "reservation-extras"
							? usei18n().translateKey("label_extras")
							: `[${category}]`;
		};
		const onBackClick = (category: CartItemCategory) => {
			const targetName = category === "bowling-experience"
				? offersPage
				: category === "footwears"
					? footwearsPage
					: category === "reservation-extras"
						? extrasPage
						: category === "food-beverage"
							? foodBeveragePage
							: null;
			if (targetName) {
				useReservationFluxStore().jumpToView({
					target: {
						...useRoute(),
						name: targetName
					}
				});
				trackUserEvent(EventAI.CartBackToStep, {
					viewName: targetName
				});
			}
		};
		const canBeRemoved = (category: CartItemCategory) => {
			return category === "food-beverage" || category === "reservation-extras";
		};
		const hasQuantifier = (category: CartItemCategory) => {
			return category === "food-beverage" || category === "reservation-extras";
		};
		const isBowlingCategory = (category: CartItemCategory) => {
			return category === "bowling-experience";
		};
		const isFootwears = (category: CartItemCategory) => {
			return category === "footwears";
		};
		const deleteItemFromCart = (item: ICartItem) => {
			useCartStore().removeItem({
				id: item.ItemId,
				category: item.Category,
				subCategory: item.SubCategory,
				compoundKey: item.ItemCompoundKey
			});
			trackUserEvent(EventAI.CartRemoveItem, {
				title: item.Title,
				category: item.Category,
				subCategory: item.SubCategory
			});
			emit("change");
		};
		const showRemoveModal = async(item: ICartItem) => {
			const confirmed = await modalConfirm(
				item.Quantity > 1
					? (usei18n().translateKey(
						"cart_confirm_remove_items_from_cart"
					) as any)
					: usei18n().translateKey("cart_confirm_remove_item_from_cart"),
				{
					title: (item.Quantity > 1 ? `${item.Quantity} x ` : "") + item.Title,
					okTitle: usei18n().translateKey("label_yes"),
					cancelTitle: usei18n().translateKey("label_no"),
					centered: true,
					scrollable: true,
					hideHeaderClose: false
				}
			);
			if (!confirmed) return;

			return deleteItemFromCart(item);
		};
		const removeItem = async(item: ICartItem) => {
			if (props.showModal) return showRemoveModal(item);

			return deleteItemFromCart(item);
		};
		const onAddClick = (cartItem: ICartItem) => {
			useCartStore().addItem({
				category: cartItem.Category,
				title: cartItem.Title ?? "",
				description: cartItem.Description ?? "",
				data: cartItem.ItemData,
				price: cartItem.UnitPrice ?? 0,
				modifiers: cartItem.Modifiers,
				note: cartItem.Note,
				quantity: 1
			});
			trackUserEvent(EventAI.CartIncreaseItemQuantity, {
				title: cartItem.Title ?? "",
				description: cartItem.Description ?? "",
				category: cartItem.Category,
				data: cartItem.ItemData,
				price: cartItem.UnitPrice ?? 0,
				modifiers: cartItem.Modifiers,
				note: cartItem.Note
			});
			emit("change");
		};
		const getFootwearLabel = (id: string | number) => {
			const item = useCartStore().getItemById(id, "footwears");
			if (!item) return "";
			return item.Quantity === 1
				? usei18n().translateKey("label_pair_one_qty")
				: usei18n().translateKey("label_pair_qty", { qty: item.Quantity });
		};
		const onSubClick = (cartItem: ICartItem) => {
			const id = cartItem.ItemId;
			const category = cartItem.Category;
			const subCategory = cartItem.SubCategory;
			const compoundKey = cartItem.ItemCompoundKey;
			const CartStore = useCartStore();
			const item = CartStore.getItemById(
				id,
				category,
				subCategory,
				compoundKey
			);
			if (item && item.Quantity === 1) {
				if (props.showModal) return showRemoveModal(item);

				return deleteItemFromCart(item);
			}
			CartStore.subItem({ id, category, subCategory, compoundKey });
			trackUserEvent(EventAI.CartReduceItemQuantity, {
				title: item?.Title,
				category: item?.Category,
				subCategory: item?.SubCategory
			});
			emit("change");
		};
		const subButtonDisabled = (item: ICartItem<any>) => {
			return item?.Quantity === minimumQuantity.value;
		};
		return {
			categories,
			systemId,
			playersLabel,
			totalWithoutTaxes,
			minimumQuantity,
			getItemsInCategory,
			getTotalInCategory,
			getLinkLabel,
			onBackClick,
			canBeRemoved,
			hasQuantifier,
			isBowlingCategory,
			isFootwears,
			showRemoveModal,
			removeItem,
			onAddClick,
			getFootwearLabel,
			onSubClick,
			subButtonDisabled,
			quantifiers
		};
	}
});

import { useRoute } from "@app-composables/router";
import { isB2CService } from "@app-router/helpers";
import useSystemStore from "@app-store/system";
import { computed, defineComponent } from "vue";

export default defineComponent({
	name: "Copyright",
	props: {},
	setup() {
		const footerCopy = computed(() => {
			return isB2CService(useRoute())
				? useSystemStore().templateCommonParts?.FooterCopy ?? ""
				: "QubicaAMF Europe spa - Via della Croce Coperta, 15 40128 Bologna, Italy - VAT IT04320910377";
		});
		return { footerCopy };
	}
});

import { ICustomerInfo } from "@app-store/user";

import PortalRestClientFactory, { IResponseError } from ".";
import type { IPolicyTerm, IPolicyTermMenu } from "./common.interfaces";
import type { ICompany, ICompanyLocationGroup, IPrivacyData, SignupBody } from "./company.interfaces";

const CustomerErrors = ["CustomerNotFound"] as const;
type CustomerErrorCodes = typeof CustomerErrors[number];
const CustomerPrivacyErrors = ["PrivacyNotFound", "CustomerExists"] as const;
type CustomerPrivacyErrorCodes = typeof CustomerPrivacyErrors[number];

export default class CompanyRestFactory extends PortalRestClientFactory {
	constructor() {
		super();
		this.options.set("basePath", "/api/companies");
	}

	info(companyId: number) {
		return this.get<ICompany>(`/${companyId}`, {
			// FIXME: Update 404 Error Code when BE can provide its value (TS: #47457)
			throwExcluding: [{ statusCode: 404 }]
		});
	}

	locations(companyId: number) {
		return this.get<ICompanyLocationGroup[]>(`/${companyId}/locations`);
	}

	privacy(companyId: number) {
		return this.get<IPrivacyData[]>(`/${companyId}/privacy`);
	}

	getCustomer(companyId: number) {
		return this.get<ICustomerInfo, IResponseError<CustomerErrorCodes>>(`/${companyId}/customers/me`, {
			throwExcluding: [error => {
			// FIXME: Remove when the BE can provide a list of all 401 Error Codes to be handled below (TS: #47457)
				if (error.statusCode === 401)
					return true;

				return ((error.statusCode === 404)
					&& typeof error.data?.Error?.Code === "string"
					&& CustomerErrors.includes(error.data.Error.Code));
			}]
		});
	}

	async linkCustomerToCompany(companyId: number, body: SignupBody) {
		return this.post<any, IResponseError<CustomerPrivacyErrorCodes>>(`/${companyId}/customers`, {
			body,
			throwExcluding: [
				error => {
					return ((error.statusCode === 404 || error.statusCode === 409)
					&& typeof error.data?.Error?.Code === "string"
					&& CustomerPrivacyErrors.includes(error.data.Error.Code));
				}]
		});
	}

	async linkCustomerToSystem(companyId: number, systemId: number) {
		return this.put<any, IResponseError<CustomerErrorCodes>>(`/${companyId}/customers/me/centers/${systemId}`, {
			cacheInMemory: false,
			throwExcluding: [error => {
				return ((error.statusCode === 404)
					&& typeof error.data?.Error?.Code === "string"
					&& CustomerErrors.includes(error.data.Error.Code));
			}]
		});
	}

	getPolicyList(companyId: number) {
		return this.get<IPolicyTermMenu[]>(`/${companyId}/Agreements`);
	}

	getPrivacyPolicy(companyId: number) {
		return this.get<IPolicyTerm>(`/${companyId}/Agreements/PrivacyPolicy`, { cacheInMemory: true });
	}
}

import { useRoute } from "@app-composables/router";
import { getReservationHomeHref, isB2CService } from "@app-router/helpers";
import useSystemStore from "@app-store/system";
import { BImg, BLink } from "bootstrap-vue";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
	name: "Logo",
	components: {
		BImg,
		BLink
	},
	props: {},
	setup() {
		const systemStore = useSystemStore();
		const imageLogo = ref<typeof BImg | null>();
		const logoUrl = computed(() => {
			return isB2CService(useRoute())
				? systemStore.templateCommonParts?.LogoUrl
				: "/assets/images/qubica-logo.png";
		});
		const isMaintenanceModeActive = computed(() => {
			return systemStore.isMaintenanceModeActive;
		});
		const logoLink = computed(() => {
			if (isMaintenanceModeActive.value) return null;
			return isB2CService(useRoute())
				? getReservationHomeHref(useRoute())
				: "https://www.qubicaamf.com/";
		});
		return {
			logoUrl,
			logoLink,
			imageLogo
		};
	}
});

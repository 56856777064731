import PortalRestClientFactory from ".";
import { ICartSummaryRequestBody, ICartSummaryResponse } from "./cart.interfaces";
export default class CartRestFactory extends PortalRestClientFactory {
	constructor() {
		super();
		this.options.set("cacheInMemory", false);
	}

	cartSummary(centerId: number, data: ICartSummaryRequestBody) {
		const headers = this.options.get("headers");
		headers.set("Content-Type", "application/json");

		return this.post<ICartSummaryResponse>(`/centers/${centerId}/Cart/CreateSummary`, {
			body: data,
			headers
		});
	}
}

export enum PolicyTermType {
	PrivacyPolicy = "PrivacyPolicy",
	TermsAndConditions = "TermsAndConditions",
	TermsOfUse = "WebSiteTerms",
	CookiePolicy = "CookiePolicy",
	KioskPlayNow = "KioskPlayNow",
}
export interface IPolicyTerm {
	IsEnabled: boolean,
	UrlLabel?: string,
	Title?: string,
	Content?: string
}
export interface IPolicyTermMenu {
	IsEnabled: boolean;
	UrlLabel: string;
	Type: PolicyTermType;
}

import { IResponseAny } from "@app-rest/index";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import { getAppData, setAppData } from "@app-utilities/app-module-storage";
import { timeout } from "@app-utilities/func";
import { MutationPayload, Store } from "vuex";
import { VuexModule } from "vuex-class-modules";

interface IPersistentPluginSettings {
	name: string
	stores: IPersistentModuleSchema[]
	onReady?: (database: IDBDatabase) => any | Promise<any>
	onUpdate?: (database: IDBDatabase) => any | Promise<any>
}
interface IPersistentModuleSchema {
	name: string
	params?: IDBObjectStoreParameters,
	indexes?: { name: string, keyPath: string | Iterable<string>, options?: IDBIndexParameters }[]
}
interface IBootHandler {
	(context: IBaseContext): Promise<any>
}
interface IMutationHandler {
	(context: IMutationContext | null): Promise<any>
}
interface IModuleHooks {
	onBoot?: IBootHandler
	onMutation?: IMutationHandler
}
export interface IBaseContext {
	access: {
		(): IAccessContext
	}
}
export interface IAccessContext {
	get: { <T = any>(key: string): Promise<T> }
	put: { (value: any, key: string): Promise<void> }
	delete: { (key: string): Promise<void> }
}
export interface IMutationContext extends IBaseContext {
	mutations: MutationPayload[]
	mutationsFind: { (name: string): MutationPayload | undefined }
	mutationsContains: { (name: string): boolean }
}
export interface IPersistentModule extends VuexModule {
	initModuleData: DataAccessAction
}
type DataAccessAction = (context: IBaseContext) => Promise<void>
interface IPersistentModuleContext {
	instance: IPersistentModule
	hooks?: IModuleHooks
}

const isBootstrappingMap = new Map<string, boolean>();
isBootstrappingMap.set("-__db_open_persistence_plugin_ready__-", true);
const modulesMap = new Map<string, IPersistentModuleContext>();
const moduleQueueMap = new Map<string, MutationPayload[]>();

let _db: IDBDatabase = null as any;
let _dbOpenRequest: IDBOpenDBRequest | null = null;
async function ensureSetup() {
	let setupAttempts = 0;
	const maxAttempts = 5;
	return new Promise<void>(resolve => {
		async function waitAndCallAgain() {
			setupAttempts += 1;
			await timeout(500);
			const setupDone = isBootstrappingMap.get("-__db_open_persistence_plugin_ready__-") === false;
			if (!setupDone && setupAttempts <= maxAttempts) {
				trackUserEvent(EventAI.IndexedDBFailure, {
					message: "Failed to ensure setup"
				});
				return waitAndCallAgain();
			}
			setupAttempts = 0;
			resolve();
		}
		const setupDone = isBootstrappingMap.get("-__db_open_persistence_plugin_ready__-") === false;
		if (!setupDone) return waitAndCallAgain();
		resolve();
	});
}
export const resolveMutationContext: IResponseAny = async(prom: Promise<any>) => {
	return new Promise<any>(resolve => {
		prom.then((response: any) =>
			resolve([response, null])
		).catch((error: any) => {
			trackUserEvent(EventAI.IndexedDBFailure, {
				message: "Failed to resolve mutation context",
				error
			});
			return resolve([null, error]);
		});
	});
};
function resolveAnyDbRequest<T = any>(request: IDBRequest): Promise<[T | null, Error | null, IDBTransaction | null]> {
	return new Promise(resolve => {
		request.onsuccess = ev => {
			const result: T = (ev.target as any).result;
			resolve([result, null, request.transaction]);
		};
		request.onerror = ev => {
			resolve([null, request.error, request.transaction]);
		};
	});
}
export function getMutationContext(moduleName: string): IMutationContext | null {
	const queue = moduleQueueMap.get(moduleName) ?? [];
	const mod = modulesMap.get(moduleName);
	if (!mod) {
		const error = new Error(`Module not found using the key '${moduleName}'`);
		trackUserEvent(EventAI.IndexedDBFailure, {
			message: "Failed to get mutation context",
			error,
			moduleName
		});
		return null;
	}

	const trackAndReturnError = <TError>(error: TError, action: string, key: string): TError => {
		trackUserEvent(EventAI.IndexedDBFailure, {
			message: "",
			error,
			moduleName,
			action,
			key
		});
		return error;
	};

	return {
		mutations: [...queue],
		access: () => {
			return {
				get: key => new Promise(async(resolve, reject) => {
					try {
						const transaction = _db.transaction(moduleName, "readonly");
						const store = transaction.objectStore(moduleName);
						const dbRequest = store.get(key);
						const [result, error] = await resolveAnyDbRequest(dbRequest);
						transaction.oncomplete = () => resolve(result);
						transaction.onerror = () => reject(trackAndReturnError(dbRequest.error, "get", key));
						transaction.onabort = () => reject(trackAndReturnError(dbRequest.error, "get", key));
						if (error)
							reject(trackAndReturnError(dbRequest.error, "get", key));
					} catch (e) {
						reject(trackAndReturnError(e, "get", key));
					}
				}),
				delete: key => new Promise(async(resolve, reject) => {
					try {
						const transaction = _db.transaction(moduleName, "readwrite");
						const store = transaction.objectStore(moduleName);
						const dbRequest = store.delete(key);
						const [result, error] = await resolveAnyDbRequest(dbRequest);
						transaction.oncomplete = () => resolve(result);
						transaction.onerror = () => reject(trackAndReturnError(dbRequest.error, "delete", key));
						transaction.onabort = () => reject(trackAndReturnError(dbRequest.error, "delete", key));
						if (error)
							reject(trackAndReturnError(dbRequest.error, "delete", key));
					} catch (e) {
						reject(trackAndReturnError(e, "delete", key));
					}
				}),
				put: (value, key) => new Promise(async(resolve, reject) => {
					try {
						const transaction = _db.transaction(moduleName, "readwrite");
						const store = transaction.objectStore(moduleName);
						const dbRequest = store.put(value, key);
						const [result, error] = await resolveAnyDbRequest(dbRequest);
						transaction.oncomplete = () => resolve(result);
						transaction.onerror = () => reject(trackAndReturnError(dbRequest.error, "put", key));
						transaction.onabort = () => reject(trackAndReturnError(dbRequest.error, "put", key));
						if (error)
							reject(trackAndReturnError(dbRequest.error, "put", key));
					} catch (e) {
						reject(trackAndReturnError(e, "put", key));
					}
				})
			};
		},
		mutationsFind: name => queue.find(m => m.type.indexOf(`/${name}`) >= 0),
		mutationsContains: name => Boolean(queue.find(m => m.type.indexOf(`/${name}`) >= 0))
	};
}
async function runModuleQueue(moduleName: string) {
	const execute = async() => {
		await ensureSetup();
		const queue = moduleQueueMap.get(moduleName) ?? [];
		const mod = modulesMap.get(moduleName);
		if (mod && queue.length) {
			moduleQueueMap.set(moduleName, []);
			if (typeof mod.hooks?.onMutation === "function") {
				const mutationContext = getMutationContext(moduleName);
				const [, error] = await resolveMutationContext(mod.hooks.onMutation(mutationContext));
				if (error) {
					trackUserEvent(EventAI.IndexedDBFailure, {
						message: "Failed to resolve mutation in moduleQueue",
						error,
						moduleName,
						mutationContext
					});
				}
			}
		}
	};
	if (isBootstrappingMap.get("-__db_open_persistence_plugin_ready__-") || isBootstrappingMap.get(moduleName)) {
		await timeout(500);
		return runModuleQueue(moduleName);
	}
	execute();
}
function getSchemaVersion(schemaDefinition: IPersistentModuleSchema[]) {
	const currentDb = getAppData("db");
	const newSchema = JSON.stringify(schemaDefinition);
	if (!currentDb) {
		setAppData("db", { version: 1, schema: newSchema });
		return 1;
	}
	if (newSchema === currentDb.schema)
		return currentDb.version;

	currentDb.version++;
	currentDb.schema = newSchema;
	setAppData("db", currentDb);
	return currentDb.version;
}
export function setupPersistentModules(settings: IPersistentPluginSettings) {
	function tryOpen() {
		const version = getSchemaVersion(settings.stores ?? []);
		const dbFromEvent = ev => {
			const target = ev.target as any;

			if (!target || !target.result)
				throw new Error("Failed to get DB from Event");

			_db = target.result as IDBDatabase;
			return _db;
		};
		_dbOpenRequest = indexedDB.open(settings.name, version);
		_dbOpenRequest.onerror = function onDbRequestError() {
			const error = _dbOpenRequest?.error;
			throw error;
		};
		_dbOpenRequest.onsuccess = async ev => {
			const db = dbFromEvent(ev);
			if (typeof settings?.onReady === "function") {
				const handler = settings.onReady(db);
				if (handler instanceof Promise)
					await handler;
			}
			isBootstrappingMap.set("-__db_open_persistence_plugin_ready__-", false);
		};
		_dbOpenRequest.onupgradeneeded = async ev => {
			const db = dbFromEvent(ev);
			const tx = _dbOpenRequest?.transaction;
			if (!tx) throw new Error("Failed to get transaction from DBRequest");

			settings.stores.forEach(sch => {
				let store: IDBObjectStore;
				if (!db.objectStoreNames.contains(sch.name))
					db.createObjectStore(sch.name, sch.params);

				sch.indexes?.forEach(ind => store.createIndex(ind.name, ind.keyPath, ind.options));
			});

			if (typeof settings?.onUpdate === "function") {
				const handler = settings.onUpdate(db);
				if (handler instanceof Promise)
					await handler;
			}
			tx.oncomplete = () => {
				isBootstrappingMap.set("-__db_open_persistence_plugin_ready__-", false);
			};
			tx.onabort = () => {
				throw new Error("Abort on database creation");
			};
			tx.onerror = () => {
				throw new Error("Error creating database");
			};
		};
		_dbOpenRequest.onblocked = async ev => {
			const event = ev as IDBVersionChangeEvent;
			throw new Error(`Request blocked opening DB - old: ${event.oldVersion} new: ${event.newVersion}`);
		};
	}

	return (store: Store<any>) => {
		tryOpen();

		function onVuexMutation(mutation) {
			const [vuexModuleName] = mutation.type.split("/");
			const queue = moduleQueueMap.get(vuexModuleName) ?? [];
			queue.push(mutation);
			moduleQueueMap.set(vuexModuleName, queue);
			const isFree = isBootstrappingMap.get(vuexModuleName) !== true;
			if (isFree)
				runModuleQueue(vuexModuleName);
		}
		store.subscribe(onVuexMutation);
	};
}
export async function usePersistentModule(moduleName: string, instance: IPersistentModule, hooks?: IModuleHooks) {
	isBootstrappingMap.set(moduleName, true);
	await ensureSetup();
	modulesMap.set(moduleName, { instance, hooks });

	const baseContext = getMutationContext(moduleName);
	if (!baseContext) return;
	await instance.initModuleData(baseContext);

	if (typeof hooks?.onBoot === "function") {
		const handler = hooks?.onBoot(baseContext);
		if (handler instanceof Promise)
			await handler;
	}
	isBootstrappingMap.set(moduleName, false);
	timeout(0).then(
		() => runModuleQueue(moduleName)
	);
}

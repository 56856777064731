import { ICartItem } from "@app-store/cart";
import { IUserFull } from "@app-store/user";
import { ConstructorOptions, EventEmitter2, OnOptions } from "eventemitter2";

import { IErrorEventHandler, IEventCartItemAdded, IEventScroll, IEventUserChanged, IEventWindowOpen, IEventWindowResize } from "./events.definitions";

class EventsManagerFactory extends EventEmitter2 {
	constructor(options?: ConstructorOptions) {
		super(options);
		this.setMaxListeners(0);
	}

	emitError(error: Parameters<IErrorEventHandler>[0], extra?: Parameters<IErrorEventHandler>[1]) {
		return super.emit("fatal-error", error, extra);
	}

	onError(handler: IErrorEventHandler, onOptions?: OnOptions) {
		const listener = super.on("fatal-error", handler, onOptions);
		return () => {
			listener.off("fatal-error", handler);
		};
	}

	emitWindowResize() {
		return super.emit("window-resize", window.innerWidth, window.innerHeight);
	}

	onWindowResize(handler: IEventWindowResize, onOptions?: OnOptions) {
		const listener = super.on("window-resize", handler, onOptions);
		return () => {
			listener.off("window-resize", handler);
		};
	}

	emitCartItemsAdded(items: ICartItem<any>[]) {
		return super.emit("cart-items-added", items);
	}

	onCartItemsAdded<T = any>(handler: IEventCartItemAdded<T>, onOptions?: OnOptions) {
		const listener = super.on("cart-items-added", handler, onOptions);
		return () => {
			listener.off("cart-items-added", handler);
		};
	}

	emitUserChanged(user: IUserFull | null, previous?: IUserFull | null) {
		return super.emit("user-changed", user, previous);
	}

	onUserChanged(handler: IEventUserChanged, onOptions?: OnOptions) {
		const listener = super.on("user-changed", handler, onOptions);
		return () => {
			listener.off("user-changed", handler);
		};
	}

	emitScrollWindow() {
		return super.emit("window-on-scroll", window.scrollX, window.scrollY);
	}

	onScrollWindow(handler: IEventScroll, onOptions?: OnOptions) {
		const listener = super.on("window-on-scroll", handler, onOptions);
		return () => {
			listener.off("window-on-scroll", handler);
		};
	}

	onWindowOpen(handler: IEventWindowOpen, onOptions?: OnOptions) {
		const listener = super.on("window-child-open", handler, onOptions);
		return () => {
			listener.off("window-child-open", handler);
		};
	}

	onceWindowOpen(handler: IEventWindowOpen, onOptions?: OnOptions) {
		const listener = super.once("window-child-open", handler, onOptions);
		return () => {
			listener.off("window-child-open", handler);
		};
	}
}

const EventsManager = new EventsManagerFactory();
const win: any = window;
if (win) {
	win._originalOpen = window.open;
	win.open = function(url?: string | URL, target?: string, features?: string) {
		const childWin = win._originalOpen(url, target, features) as Window;
		EventsManager.emit("window-child-open", childWin, url, target, features);
		return childWin;
	};
}
export default EventsManager;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { useRouter } from "@app-composables/router";
import type { IReservationData, ReservationViewName } from "@app-rest/reservations.interfaces";
import type { IPlayersQuantity } from "@app-rest/systems.interfaces";
import router from "@app-router/.";
import { getReservationHome } from "@app-router/helpers";
import { cartPage, extrasPage, foodBeveragePage, footwearsPage, home, offersPage, reservationBowlersPage, thankyouPage } from "@app-router/routes/reservations";
import useAppStore from "@app-store/app";
import useCartStore, { cartItemToSetter, CartModuleInit } from "@app-store/cart";
import useReservationStore, { ReservationModuleInit } from "@app-store/reservation";
import useSystemStore from "@app-store/system";
import useUserStore, { notifyUserSessionExpiredLogout, UserModuleInit } from "@app-store/user";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import { isMandatoryPage, type MandatoryPage } from "@app-utilities/const";
import EventManager from "@app-utilities/events-manager";
import { getQueryStringFormData } from "@app-utilities/form-data";
import { timeout, timeoutStoppable } from "@app-utilities/func";
import { clearFluxPersistence, notifyBackToWebOffersPage, notifyReservationAlreadyConfirmed, notifyReservationExpired, notifyReservationLeftNotAvailable, notifyReservationResumeAvailable, notifySelectedOfferNotAvailable, persistenceKey } from "@app-utilities/reservation-flux";
import { Route } from "vue-router";
import { Action, Module, VuexModule } from "vuex-class-modules";

import store from ".";
import { getMutationContext, resolveMutationContext } from "./persistent.plugin";

interface IReservationNextResult {
	next: ReservationViewName,
	go: boolean
}

@Module class ReservationFluxStoreFactory extends VuexModule {
	get viewSequence(): ReservationViewName[] {
		const reservationStore = useReservationStore();
		const sequence = [offersPage, footwearsPage, cartPage, reservationBowlersPage, thankyouPage];

		if (reservationStore.options?.ShowGamesAndExtraPage)
			sequence.splice(sequence.indexOf(cartPage), 0, extrasPage as any);

		if (reservationStore.options?.ShowFoodAndBeveragePage)
			sequence.splice(sequence.indexOf(cartPage), 0, foodBeveragePage as any);

		return sequence as ReservationViewName[];
	}

	get isInFlux() {
		return (viewName: string) => {
			if (!this.viewSequence.length) throw new Error("ViewSequence flux is empty.");

			return this.viewSequence.indexOf(viewName as any) >= 0;
		};
	}

	get isInBookingFlux() {
		return (viewName: string) => {
			const minIndex = this.viewSequence.indexOf(offersPage) + 1;
			const maxIndex = this.viewSequence.indexOf(cartPage);
			const currentView = this.viewSequence.indexOf(viewName as any);
			return currentView >= minIndex && currentView <= maxIndex;
		};
	}

	get isReservationAlive() {
		const reservationStore = useReservationStore();

		const reservationStillValid = reservationStore.reservationKeyExpire
		&& new Date() < reservationStore.reservationKeyExpire;

		return Boolean(reservationStore.reservationKey && (reservationStillValid || reservationStore.isReservationConfirmed || reservationStore.isReservationTemporary));
	}

	get getPrevView() {
		return (currentViewName?: ReservationViewName): ReservationViewName => {
			if (!currentViewName) return this.viewSequence[0];
			let index = this.viewSequence.indexOf(currentViewName) as number;
			if (index < 0) return this.viewSequence[0];

			index--;
			if (index < 0) index = 0;
			return this.viewSequence[index];
		};
	}

	get getNextView() {
		return (currentViewName?: ReservationViewName): ReservationViewName => {
			if (!currentViewName) return this.viewSequence[0];
			if (this.viewSequence.indexOf(currentViewName) < 0) return this.viewSequence[0];

			let index = this.viewSequence.indexOf(currentViewName) as number;
			if (index < 0) return this.viewSequence[0];
			index++;
			if (index >= this.viewSequence.length) index = this.viewSequence.length - 1;
			return this.viewSequence[index];
		};
	}

	get fluxVirtuallyEnded() {
		const reservationStore = useReservationStore();
		return reservationStore.reservationNeedPayment == null
			? false
			: reservationStore.reservationNeedPayment
				? useCartStore().isPaid
				: reservationStore.isReservationConfirmed;
	}

	private validateView(view: ReservationViewName) {
		const reservationStore = useReservationStore();
		if (view === cartPage) {
			const fields = [reservationStore.pickedDatetime, reservationStore.bowlersTotal];
			return fields.filter(Boolean).length > 0;
		} else if (view === reservationBowlersPage || view === thankyouPage)
			return this.fluxVirtuallyEnded;

		const fluxStartedViews = [footwearsPage, extrasPage, foodBeveragePage];
		if (fluxStartedViews.indexOf(view) >= 0)
			return Boolean(reservationStore.offer);

		return true;
	}

	private goToRouteAndSetLatest(currentRoute: Route, result: IReservationNextResult) {
		const reservationStore = useReservationStore();
		const params = {
			...currentRoute.params,
			reskey: reservationStore.reservationKey ?? ""
		};
		let query = currentRoute.query;
		if (result.next === offersPage) {
			query = {
				...query,
				...getQueryStringFormData({
					datetime: reservationStore.formPickedDatetime,
					bowlers: reservationStore.bowlers
				})
			};
		}
		return router.push({
			name: result.next,
			params,
			query
		}).catch(() => {}).finally(() => {
			if (isMandatoryPage(result.next)) reservationStore.setMandatoryPageVisited(result.next as MandatoryPage);
			reservationStore.setLatestView(result.next);
		});
	}

	@Action async prefetchAndValidateView(targetView: ReservationViewName): Promise<IReservationNextResult> {
		const nextToOffersPageIndex = this.viewSequence.findIndex(v => v === offersPage) + 1;
		const targetIndex = this.viewSequence.findIndex(v => v === targetView);

		const systemStore = useSystemStore();
		const systemId = systemStore.Id;

		const reservationStore = useReservationStore();
		const datetime = reservationStore.pickedDatetime;
		const offerId = reservationStore.offer?.OfferId;

		if (targetIndex >= nextToOffersPageIndex && useUserStore().isSessionExpired()) {
			await notifyUserSessionExpiredLogout();
			return { next: home, go: true };
		}

		if (reservationStore.reservationKey && !(reservationStore.isReservationConfirmed || reservationStore.isReservationTemporary) && !this.isReservationAlive) {
			await notifySelectedOfferNotAvailable();
			reservationStore.clearReservation();
			return { next: offersPage, go: true };
		}

		if (targetView === offersPage)
			await systemStore.ensureGenericPage({ systemId, pageType: "WEB OFFERS" });

		else if (targetView === footwearsPage) {
			if (!datetime)
				throw new Error("Footwears Page: datetime undefined");

			if (!offerId)
				throw new Error("Footwears Page: offerId undefined");

			const isValid = this.validateView(targetView);
			if (!isValid)
				return this.prefetchAndValidateView(offersPage);

			await Promise.all([
				systemStore.ensureGenericPage({ systemId, pageType: "SHOES AND SOCKS" }),
				reservationStore.syncFootwears({ systemId, datetime, offerId })
			]);
			const hasFootwearData = Boolean(reservationStore.shoes?.length) || Boolean(reservationStore.socks?.length);
			return { next: targetView, go: hasFootwearData };
		} else if (targetView === extrasPage) {
			if (!datetime)
				throw new Error("Extras Page: datetime undefined");

			if (!offerId)
				throw new Error("Extras Page: offerId undefined");

			const isValid = this.validateView(targetView);
			if (!isValid)
				return this.prefetchAndValidateView(offersPage);

			await Promise.all([
				systemStore.ensureGenericPage({ systemId, pageType: "GAMES AND EXTRAS" }),
				reservationStore.syncExtras({ systemId, datetime, offerId })
			]);
			return { next: targetView, go: Boolean(reservationStore.extras?.length) };
		} else if (targetView === foodBeveragePage) {
			if (!datetime)
				throw new Error("Food & Beverage Page: datetime undefined");

			if (!offerId)
				throw new Error("Food & Beverage Page: offerId undefined");

			const isValid = this.validateView(targetView);
			if (!isValid)
				return this.prefetchAndValidateView(offersPage);

			const getStatusFoodAndBeverageAvailableAt = async(date: Date) => {
				if (!systemStore.info?.Id) return;
				const restClient = await systemStore.ensureRestClient();
				const response = await restClient.statusFoodAndBeverageAvailableAt(systemStore.info.Id, date);
				return response;
			};

			const actualFoodAndBeverageStatus = await getStatusFoodAndBeverageAvailableAt(datetime);
			const isFoodAndBeverageAvailableNow = !actualFoodAndBeverageStatus || !actualFoodAndBeverageStatus.data ? false : actualFoodAndBeverageStatus.data.FoodAndBeverageAllowedAtSelectedTime;

			if (!isFoodAndBeverageAvailableNow) return { next: targetView, go: false };

			await Promise.all([
				systemStore.ensureGenericPage({ systemId, pageType: "FOOD AND DRINK" }),
				reservationStore.syncFoodBeverageCategories({ systemId, datetime, offerId })
			]);

			const categories = reservationStore.foodBeverageCategories;
			const categoryId = categories && categories.length
				? categories[0].CategoryId
				: null;
			if (datetime && categoryId)
				await reservationStore.syncFoodBeverage({ systemId, datetime, categoryId });

			const fb = reservationStore.foodBeverages.get(categoryId ?? 0) ?? [];
			return { next: targetView, go: Boolean(categoryId && fb.length) };
		} else if (targetView === cartPage || targetView === reservationBowlersPage || targetView === thankyouPage) {
			await Promise.all([
				systemStore.ensureGenericPage({ systemId, pageType: "REGISTER" }),
				systemStore.ensureGenericPage({ systemId, pageType: "YOUR ORDER" }),
				systemStore.ensureTemplateReservation(),
				systemStore.ensureTemplateThankYou(),
				systemStore.ensureProviderConfiguration()
			]);

			const isValid = this.validateView(targetView);
			const nextToCart = this.getNextView(cartPage);
			const nextViewOnInvalid = targetView === cartPage
				? nextToCart
				: (this.validateView(cartPage) ? cartPage : offersPage);
			if (!isValid)
				return { next: nextViewOnInvalid, go: true };
		}

		return { next: targetView, go: true };
	}

	@Action async goPrevView(currentRoute: Route) {
		const currentView = currentRoute.name as ReservationViewName;

		await useSystemStore().ensureCurrency();

		const findAndGo = async(viewName: ReservationViewName) => {
			const prevView = this.getPrevView(viewName);
			if (!prevView) return;

			const result = await this.prefetchAndValidateView(prevView);
			if (result.go)
				return this.goToRouteAndSetLatest(currentRoute, result);

			findAndGo(result.next);
		};

		findAndGo(currentView);
		trackUserEvent(EventAI.FlowPreviousStep);
	}

	@Action async goNextView(currentRoute: Route) {
		const currentView = currentRoute.name as ReservationViewName;

		await useSystemStore().ensureCurrency();

		const findAndGo = async(viewName: ReservationViewName) => {
			const nextView = this.getNextView(viewName);
			if (!nextView) return;

			const result = await this.prefetchAndValidateView(nextView);
			if (result.go)
				return this.goToRouteAndSetLatest(currentRoute, result);

			findAndGo(result.next);
		};
		findAndGo(currentView);
		trackUserEvent(EventAI.FlowNextStep);
	}

	@Action async jumpToView(context: { target: Route, ready?: Function }) {
		const viewName = context.target.name as ReservationViewName;

		await useSystemStore().ensureCurrency();
		const result = await this.prefetchAndValidateView(viewName);
		return result.go ? this.goToRouteAndSetLatest(context.target, result) : undefined;
	}
}

let _isUserDataSynced = false;
export function ensureUserSynced() {
	return new Promise<void>(resolve => {
		async function check() {
			if (_isUserDataSynced) return resolve();
			await timeout(500);
			check();
		}
		check();
	});
}
async function onFluxUserChanged(user, prev) {
	_isUserDataSynced = false;
	await Promise.all([
		UserModuleInit,
		CartModuleInit,
		ReservationModuleInit
	]);
	const userDbContext = getMutationContext("user");
	const cartDbContext = getMutationContext("cart");
	const reservationDbContext = getMutationContext("reservation");
	const systemId = useSystemStore().Id;
	const reservationStore = useReservationStore();

	async function syncStoreWithDb(moduleName: "cart" | "reservation", data?: any) {
		if (moduleName === "reservation") {
			const dbData: IReservationData = data ?? {
				bowlers: [] as IPlayersQuantity[],
				bowlersTotal: 0,
				latestView: null,
				pickedDatetime: null,
				reservationStatus: null,
				reservationNeedPayment: null,
				reservationKey: null,
				reservationToken: null,
				reservationKeyExpire: null,
				reservationStatusCode: 0,
				paymentStatus: null,
				systemId,
				offer: null
			};
			reservationStore.setData(dbData);
		} else {
			const items = data?.items ?? [];
			const cartStore = useCartStore();
			cartStore.setItems(items.map(cartItemToSetter));
			await cartStore.handleCreatedAt(data?.createdAt ?? null);
		}
	}
	function getStoreAccess(moduleName: "cart" | "reservation") {
		if (!reservationDbContext || !cartDbContext) return;
		return moduleName === "reservation"
			? reservationDbContext.access()
			: cartDbContext.access();
	}
	async function clearUserData(key: string) {
		if (!userDbContext) return;
		const accessCtx = userDbContext.access();
		if (key)
			await resolveMutationContext(accessCtx.delete(key));
	}

	await ensureResKeyResolved();
	const guestKey = await persistenceKey("guest");
	const toLoggedIn = user?.isLoggedIn && !prev?.isLoggedIn;
	const toLoggedOut = !user?.isLoggedIn && prev?.isLoggedIn;
	let missingDataOnDb = false;

	if (toLoggedIn) {
		const loggedUserKey = await persistenceKey(useUserStore().Email);
		// eslint-disable-next-line no-inner-declarations
		async function syncLoggedData(moduleName: "cart" | "reservation") {
			if (!loggedUserKey || !guestKey) return;

			const accessCtx = getStoreAccess(moduleName);
			let transferDataFromGuest = false;
			if (!accessCtx) return;
			let [userModuleData] = await resolveMutationContext(accessCtx.get(loggedUserKey));
			if (!userModuleData) {
				[userModuleData] = await resolveMutationContext(accessCtx.get(guestKey));

				if (!userModuleData)
					missingDataOnDb = true;
				else {
					await resolveMutationContext(accessCtx.delete(guestKey));
					transferDataFromGuest = true;
				}
			}

			await syncStoreWithDb(moduleName, userModuleData);
			if (moduleName === "reservation" && transferDataFromGuest)
				await clearUserData(`guest-${useSystemStore().Id}`);
		}

		await syncLoggedData("reservation");
		await syncLoggedData("cart");
	} else if (toLoggedOut) {
		// eslint-disable-next-line no-inner-declarations
		async function clearLoggedData(moduleName: "cart" | "reservation") {
			const accessCtx = getStoreAccess(moduleName);
			if (!accessCtx) return;
			const loggedUserKey = await persistenceKey(prev.Email);

			if (loggedUserKey)
				await resolveMutationContext(accessCtx.delete(loggedUserKey));
		}
		await clearLoggedData("reservation");
		await clearLoggedData("cart");
		await clearUserData(prev.Email);
		_isUserDataSynced = true;
		return clearAndGoHome();
	} else {
		// eslint-disable-next-line no-inner-declarations
		async function syncGuestData(moduleName: "cart" | "reservation") {
			const accessCtx = getStoreAccess(moduleName);
			if (!accessCtx) return;

			if (reservationStore.isReservationConfirmed || reservationStore.isReservationTemporary) {
				if (guestKey)
					await resolveMutationContext(accessCtx.delete(guestKey));
				const prevUserKey = await persistenceKey(prev?.Email);
				if (prevUserKey)
					await resolveMutationContext(accessCtx.delete(prevUserKey));
			} else if (guestKey) {
				const [guestData] = await resolveMutationContext(accessCtx.get(guestKey));
				if (!guestData)
					missingDataOnDb = true;

				await syncStoreWithDb(moduleName, guestData);
			} else
				await syncStoreWithDb(moduleName, null);
		}
		await syncGuestData("reservation");
		await syncGuestData("cart");
	}

	if (missingDataOnDb) {
		trackUserEvent(EventAI.FlowEndUnsuccessful, { missingDataOnDb });
		await notifyReservationLeftNotAvailable();
		return clearAndGoHome();
	}

	_isUserDataSynced = true;
}
EventManager.onUserChanged((user, prev) => onFluxUserChanged(user, prev));

export function clearReservationKeysPersistence(keys: string[]) {
	const userStore = useUserStore();
	const cartDbContext = getMutationContext("cart");
	const reservationDbContext = getMutationContext("reservation");
	if (!cartDbContext || !reservationDbContext) return;
	const cartCtx = cartDbContext.access();
	const resCtx = reservationDbContext.access();

	keys.forEach(async resKey => {
		const guestKey = await persistenceKey("guest", resKey);
		if (guestKey && userStore.isGuestWithData) {
			resolveMutationContext(cartCtx.delete(guestKey));
			resolveMutationContext(resCtx.delete(guestKey));
		}
		if (userStore.isLoggedIn) {
			const userKey = await persistenceKey(userStore.Email, resKey);
			if (userKey) {
				resolveMutationContext(cartCtx.delete(userKey));
				resolveMutationContext(resCtx.delete(userKey));
			}
		}
	});
}
export async function clearFluxData() {
	const userStore = useUserStore();
	await clearFluxPersistence();
	if (!userStore.isLoggedIn) {
		userStore.clearData();
		userStore.clearPersistedData();
	}

	userStore.cleanExpiredReservationsFromHistory();
	useCartStore().clearData();
	useReservationStore().clearData();
}
export function redirectToReservationHome() {
	const { location } = getReservationHome();
	const router = useRouter();
	trackUserEvent(EventAI.RedirectToHome, {
		fromPath: router.currentRoute.path,
		queryPath: router.currentRoute.query
	});
	return router.push(location).catch(() => {});
}
async function clearAndGoHome() {
	await clearFluxData();
	await redirectToReservationHome();
	return { preventNext: true };
}
let _resKeyResolved = false;
function ensureResKeyResolved() {
	return new Promise<void>(resolve => {
		async function check() {
			if (_resKeyResolved) return resolve();
			await timeout(500);
			check();
		}
		check();
	});
}
function resolveResKey(to: Route) {
	const reservationStore = useReservationStore();
	if (reservationStore.reservationKey) {
		_resKeyResolved = true;
		return;
	}

	const resKeyFromUrl: string | undefined = to.params.reskey;
	if (!reservationStore.reservationKey) {
		_resKeyResolved = false;
		if (resKeyFromUrl)
			reservationStore.setReservationKey(resKeyFromUrl);

		_resKeyResolved = true;
		return;
	}

	if (resKeyFromUrl && (!reservationStore.reservationKey || resKeyFromUrl !== reservationStore.reservationKey))
		reservationStore.setReservationKey(resKeyFromUrl);

	_resKeyResolved = true;
}

async function resumeFlux(to: Route, targetView: ReservationViewName, reskey: string): Promise<void | IProcessBookingResult> {
	startCartChecker();
	trackUserEvent(EventAI.FlowResume, {
		reservationKey: reskey
	});
	useReservationFluxStore().jumpToView({
		target: {
			...to,
			name: targetView,
			params: {
				...to.params,
				reskey
			}
		}
	});
	return { preventNext: true };
}
let _isResuming = true;
interface IProcessBookingResult {
	preventNext: boolean
}
export async function processBookingRoute(to: Route, from: Route): Promise<void | IProcessBookingResult> {
	if (from.name === cartPage && to.name === cartPage)
		return;

	const cartStore = useCartStore();
	const reservationFluxStore = useReservationFluxStore();
	const reservationStore = useReservationStore();
	const userStore = useUserStore();
	const appStore = useAppStore();

	const isGoingHome = to.name === home;
	if (isGoingHome && "action" in to.query && to.query.action === "signup") {
		userStore.signup();
		router.replace({
			name: home,
			query: {},
			params: {
				...to.params
			}
		});
		return;
	}

	await UserModuleInit;
	resolveResKey(to);

	if (_isResuming) {
		const current = userStore.isLoggedIn ? userStore.userData : null;
		await onFluxUserChanged(current, null);
	}
	await ensureUserSynced();

	const isGoingHomeWithActiveReservation = Boolean(from.name && isGoingHome && reservationStore.reservationKey);

	if (isGoingHomeWithActiveReservation) {
		let mustDoResume = false;
		if (!(reservationStore.isReservationConfirmed || reservationStore.isReservationTemporary))
			mustDoResume = await notifyReservationResumeAvailable();

		if (!mustDoResume) {
			appStore.setLoadingView(true);
			if (reservationStore.reservationKey)
				await reservationStore.reservationDelete();

			trackUserEvent(EventAI.FlowEndByUser, { code: "IsGoingHomeWithActiveReservation" });
			return clearAndGoHome();
		} else {
			await reservationFluxStore.jumpToView({
				target: {
					...from
				}
			});
			appStore.setLoadingView(false);
			return { preventNext: true };
		}
	}

	const isGoingBackWebOffers = Boolean(to.name === offersPage && reservationStore.reservationKey);
	if (isGoingBackWebOffers) {
		const goToWebOffer = await notifyBackToWebOffersPage();

		if (goToWebOffer) {
			appStore.setLoadingView(true);
			await reservationStore.reservationDelete();
			await clearAndGoHome();
		}
		appStore.setLoadingView(false);
		return { preventNext: true };
	}

	const isFluxVirtuallyEnded = reservationFluxStore.fluxVirtuallyEnded;
	if (!isFluxVirtuallyEnded && reservationStore.offer?.OfferId && useSystemStore().Id) {
		await reservationStore.ensureOfferOptions(useSystemStore().Id);
		reservationStore.ensureMandatoryPages();
	}
	const isViewInReservationFlux = useReservationFluxStore().isInFlux(to.name ?? "");
	const requireTryRenew = reservationStore.reservationKey && !isFluxVirtuallyEnded
		&& (isViewInReservationFlux || isGoingHome)
		&& to.name !== offersPage;

	if (requireTryRenew) {
		const tryRenewResponse = await reservationStore.reservationTryRenew();
		const isReservationExpired = !tryRenewResponse?.data?.ReservationKey;
		const isReservationAlreadyConfirmed = Boolean(tryRenewResponse?.error?.data?.Error?.Code === "ReservationAlreadyConfirmed");
		const isReservationNotRenewable = !tryRenewResponse || (tryRenewResponse && isReservationExpired) || isReservationAlreadyConfirmed;
		const hasNewReservationKeyFromAPI = tryRenewResponse && tryRenewResponse.data && tryRenewResponse.data.ReservationKey && to.params.reskey
		&& to.params.reskey !== tryRenewResponse.data.ReservationKey;

		if (isReservationNotRenewable) {
			if (isReservationAlreadyConfirmed)
				await notifyReservationAlreadyConfirmed();
			else
				await notifyReservationLeftNotAvailable();

			const code = isReservationExpired ? "ReservationExpired" : isReservationAlreadyConfirmed ? "ReservationAlreadyConfirmed" : "ReservationNoLongerAvailable";
			trackUserEvent(EventAI.FlowEndUnsuccessful, { code });
			return clearAndGoHome();
		}

		if (hasNewReservationKeyFromAPI) {
			const mustDoResume = await notifyReservationResumeAvailable();

			const latestView = reservationStore.latestView;

			if (mustDoResume && latestView && tryRenewResponse.data) {
				_isResuming = false;
				return resumeFlux(to, latestView, tryRenewResponse.data.ReservationKey);
			}
			trackUserEvent(EventAI.FlowEndByUser, { code: "HasNewReservationKeyFromAPI" });
			return clearAndGoHome();
		}
	}

	const cartExpired = cartStore.isExpired();
	const cartHasItems = cartStore.items.length > 0;
	const reservationHasFormData = reservationStore.hasReservationFormData;

	if (_isResuming) {
		if (isFluxVirtuallyEnded && isViewInReservationFlux && (!cartHasItems || !reservationHasFormData)) {
			if (isGoingHome) return;

			return clearAndGoHome();
		}

		if (isFluxVirtuallyEnded || (!isFluxVirtuallyEnded && cartExpired))
			return clearAndGoHome();

		if (!isFluxVirtuallyEnded && cartHasItems && reservationHasFormData) {
			const mustDoResume = await notifyReservationResumeAvailable();

			const latestView = reservationStore.latestView;

			if (mustDoResume && latestView) {
				_isResuming = false;
				return resumeFlux(to, latestView, to.params.reskey);
			}

			if (reservationStore.reservationKey)
				await reservationStore.reservationDelete();

			return clearAndGoHome();
		}
	} else {
		if (isFluxVirtuallyEnded || !cartExpired)
			return;

		if (isGoingHome || isViewInReservationFlux) {
			await clearFluxData();
			await notifyReservationLeftNotAvailable();
		}
	}

	_isResuming = false;
}
let _cartCheckerTimeoutId = 0;
let _cartCheckerIsResuming = true;
export async function startCartChecker() {
	if (_cartCheckerTimeoutId || _cartCheckerIsResuming) return;
	else stopCartChecker();

	_cartCheckerIsResuming = false;

	const checker = async() => {
		if (_cartCheckerTimeoutId)
			stopCartChecker();

		const cartStore = useCartStore();
		await CartModuleInit;
		const timeout = timeoutStoppable(cartStore.maxLife - 1000);
		_cartCheckerTimeoutId = timeout.id;
		await timeout.end;
		if (cartStore.isExpired()) {
			await clearFluxData();
			await notifyReservationExpired();
			return redirectToReservationHome();
		}
		checker();
	};
	checker();
}
export function stopCartChecker() {
	clearTimeout(_cartCheckerTimeoutId);
	_cartCheckerTimeoutId = 0;
}

const moduleName = "reservationFlux";

let ReservationFluxStore: ReservationFluxStoreFactory | null;

function useReservationFluxStore() {
	if (ReservationFluxStore) return ReservationFluxStore;

	const mod = ReservationFluxStore = new ReservationFluxStoreFactory({ store, name: moduleName });

	return mod;
}

export default useReservationFluxStore;

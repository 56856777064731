import Layout from "@app-components/layout.vue";
import { useRoute } from "@app-composables/router";
import { IPolicyTerm, PolicyTermType } from "@app-rest/common.interfaces";
import { IGenericPage } from "@app-rest/systems.interfaces";
import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import useSystemStore from "@app-store/system";
import { camelCase, flow, upperFirst } from "lodash-es";
import { computed, defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
	name: "PolicyTermPage",
	components: {
		Layout
	},
	props: {},
	setup() {
		const themeLoaded = computed(() => {
			return useAppStore().themeLoaded;
		});
		const type = computed(() => {
			return flow(upperFirst)(
				camelCase(useRoute().params.type)
			) as PolicyTermType;
		});
		const data = ref<Partial<IPolicyTerm> | IGenericPage>({});

		const systemStore = useSystemStore();
		const companyStore = useCompanyStore();
		const systemInfoId = systemStore.info?.Id;
		const ensureRestClient = systemStore.ensureRestClient();

		const getPolicyTerm = async() => {
			if (type.value === PolicyTermType.CookiePolicy) {
				if (!systemInfoId) return;
				const restClient = await ensureRestClient;
				return restClient.templateCookiePage(systemInfoId);
			}
			if (type.value === PolicyTermType.PrivacyPolicy) {
				const restClient = await companyStore.ensureRestClient();
				return restClient.getPrivacyPolicy(companyStore.Id);
			}
			if (type.value === PolicyTermType.TermsOfUse) {
				if (!systemInfoId) return;
				const restClient = await ensureRestClient;
				return restClient.getTermsOfUse(systemInfoId);
			}
			if (type.value === PolicyTermType.TermsAndConditions) {
				if (!systemInfoId) return;
				const restClient = await ensureRestClient;
				return restClient.getTermsAndConditions(systemInfoId);
			}
			return Promise.resolve(null);
		};
		const loadContent = async() => {
			const response = await getPolicyTerm();
			data.value = response?.data ?? {};
		};
		watch(
			() => type.value,
			async() => await loadContent()
		);
		onMounted(async() => {
			await loadContent();
			useAppStore().setLoadingView(false);
		});
		return {
			themeLoaded,
			type,
			data,
			onMounted,
			getPolicyTerm
		};
	}
});

/* eslint-disable @typescript-eslint/no-use-before-define */
import usei18n from "@app-i18n/index";
import { PolicyTermType } from "@app-rest/common.interfaces";
import CompanyRestFactory from "@app-rest/company";
import { PolicyType, type SignupBody } from "@app-rest/company.interfaces";
import { resolveAny } from "@app-rest/index";
import router from "@app-router/index";
import { policyPage } from "@app-router/routes/policyTerms";
import { cartPage } from "@app-router/routes/reservations";
import useAppStore, { AzureADB2CFlow } from "@app-store/app";
import useCartStore from "@app-store/cart";
import useCompanyStore from "@app-store/company";
import useLangStore from "@app-store/lang";
import { clearReservationKeysPersistence } from "@app-store/reservation-flux";
import useRestUtilsStore from "@app-store/rest-utils";
import useSystemStore from "@app-store/system";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import EventsManager from "@app-utilities/events-manager";
import { timeout } from "@app-utilities/func";
import { modalEmpty, modalOk, modalWarning } from "@app-utilities/modals";
import { azurePopupWindowFocus, setAzurePopupWindow } from "@app-utilities/popup";
import { waitForSystem } from "@app-utilities/system";
import { toastSuccess } from "@app-utilities/toasts";
import { AccountInfo, AuthenticationResult, AuthError, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { Location } from "vue-router";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import BowlerApp from "@/main";

import store from ".";
import { IBaseContext, IPersistentModule, resolveMutationContext, usePersistentModule } from "./persistent.plugin";

const restUtilsStore = useRestUtilsStore();

export interface ILoginRedirectTo extends Pick<Location, "name" | "params" | "query"> {
	flow?: AzureADB2CFlow
	language: string
}
export interface IUserBase {
	AccessToken: string | null;
}
export interface ISignupParameters {
	companyId: number,
	privacyData: SignupBody
}
export interface IUserData extends IBaseUserData {
	LastName: string;
	isLoggedIn: boolean;
	isGuestWithData: boolean;
}
export interface IBaseUserData {
	FirstName: string;
	Phone: string;
	Email: string;
}
export interface IPhone {
	Number: string,
	Type: string
}
export interface IFrequentBowler {
	CenterId: number,
	CustomerId: number
}
export interface IPrivacyChoice {
	PrivacyName: PolicyType,
	PrivacyVersion: number,
	Accepted: boolean
}
export interface IAssociatedSystem {
	CenterId: number
}
export interface ICustomerInfo {
	AzureOId: string,
	AzureId: number,
	FirstName: string,
	LastName: string,
	Emails: string[],
	Phones: IPhone[],
	FrequentBowler: IFrequentBowler,
	PrivacyChoices: IPrivacyChoice[],
	AssociatedCenters: IAssociatedSystem[]
}
export interface IIdTokenClaims {
	at_hash: string;
	aud: string;
	auth_time: number;
	/**
	 *
	 * @type {string[]}
	 * @memberof IIdTokenClaims
	 * @name emails
	 * @returns list of array of the user
	 */
	emails: string[];
	exp: number;
	extension_Phone: string,
	/**
	 *
	 *
	 * @type {string}
	 * @memberof IIdTokenClaims
	 * @name family_name
	 * @description Last Name of the user
	 */
	family_name: string;
	/**
	 *
	 *
	 * @type {string}
	 * @memberof IIdTokenClaims
	 * @name given_name
	 * @description First Name of the user
	 */
	given_name: string;
	iat: number;
	iss: string;
	/**
	 *
	 *
	 * @type {string}
	 * @memberof IIdTokenClaims
	 * @name name
	 * @description First + Last Name of the user
	 */
	name: string;
	nbf: number;
	nonce: string;
	/**
	 *
	 *
	 * @type {string}
	 * @memberof IIdTokenClaims
	 * @name oid
	 * @description Object Identifier description
	 */
	oid: string;
	sub: string;
	tfp: string;
	ver: string;
}
export interface IUserFull extends IUserBase, IUserData {
	AccountInfo: AccountInfo | null;
	reservationsHistory: IReservationKeyHistoryItem[];
}
interface IReservationKeyHistoryItem {
	key: string,
	createdAt: Date
}
interface IAuthError extends Error {
	errorCode: string
	errorMessage: string
}

@Module class UserStoreFactory extends VuexModule implements IUserFull, IPersistentModule {
	restClient: CompanyRestFactory | null = null;
	AccessToken: string | null = null;
	FirstName = "";
	LastName = "";
	Email = "";
	Phone = "";
	AccountInfo: AccountInfo | null = null;
	CustomerInfo: ICustomerInfo | null = null;
	SessionExpireAt: Date | null = null;
	reservationsHistory: IReservationKeyHistoryItem[] = [];
	isLinkedToCompany = false;
	isLinkedToSystem = false;

	get isLoggedIn() {
		return Boolean(this.AccessToken && restUtilsStore.isAccessTokenValid);
	}

	get isGuestWithData() {
		return !this.isLoggedIn && Boolean(this.Email);
	}

	get isSessionExpired() {
		return () => this.isLoggedIn && this.SessionExpireAt && Date.now() > +this.SessionExpireAt;
	}

	get userData(): IUserFull {
		return {
			FirstName: this.FirstName,
			LastName: this.LastName,
			Email: this.Email,
			Phone: this.Phone,
			isLoggedIn: this.isLoggedIn,
			isGuestWithData: this.isGuestWithData,
			AccessToken: this.AccessToken,
			AccountInfo: this.AccountInfo,
			reservationsHistory: this.reservationsHistory
		};
	}

	get acceptedPrivacy() {
		if (!this.CustomerInfo) return null;

		const el = this.CustomerInfo.PrivacyChoices.find(el => el.PrivacyName === PolicyType.TermsAndConditions);
		return Boolean(el && el.Accepted);
	}

	get acceptedOrDeclinedOffers() {
		if (!this.CustomerInfo) return null;

		const el = this.CustomerInfo.PrivacyChoices.find(el => el.PrivacyName === PolicyType.ReceiveOffers);
		return Boolean(el);
	}

	@Mutation clearData() {
		this.AccessToken = null;
		this.FirstName = "";
		this.LastName = "";
		this.Email = "";
		this.Phone = "";
		this.AccountInfo = null;
		this.SessionExpireAt = null;
		this.reservationsHistory = [];
		this.CustomerInfo = null;
		this.isLinkedToCompany = false;
		this.isLinkedToSystem = false;
	}

	@Mutation clearPersistedData() {
		// triggers IndexedDB user deletion only (not from store)
	}

	@Mutation setUserData(userData: Partial<IUserFull> | null) {
		this.AccessToken = userData?.AccessToken ?? null;
		this.FirstName = userData?.FirstName ?? "";
		this.LastName = userData?.LastName ?? "";
		this.Email = userData?.Email ?? "";
		this.Phone = userData?.Phone ?? "";
		this.AccountInfo = userData?.AccountInfo ?? null;
		if (userData?.reservationsHistory)
			this.reservationsHistory = userData.reservationsHistory;

		this.CustomerInfo = null;
		this.isLinkedToCompany = false;
		this.isLinkedToSystem = false;
	}

	@Mutation setCustomerInfo(data: ICustomerInfo | null) {
		this.CustomerInfo = data;
	}

	@Mutation setIsLinkedToCompany(state: boolean) {
		this.isLinkedToCompany = state;
	}

	@Mutation setIsLinkedToSystem(state: boolean) {
		this.isLinkedToSystem = state;
	}

	private _findReservationKeyHistoryIndex(key: string) {
		if (!this.reservationsHistory.length) return -1;
		return this.reservationsHistory.findIndex(el => el.key === key);
	}

	@Mutation reservationsHistoryAdd(key: string) {
		const index = this._findReservationKeyHistoryIndex(key);
		if (index !== -1) return;

		this.reservationsHistory.push({
			key, createdAt: new Date()
		});
	}

	@Mutation reservationsHistoryRemove(key: string) {
		const index = this._findReservationKeyHistoryIndex(key);
		if (index < 0) return;
		this.reservationsHistory.splice(index, 1);
	}

	@Mutation cleanExpiredReservationsFromHistory() {
		const expired: IReservationKeyHistoryItem[] = [];
		const notExpired: IReservationKeyHistoryItem[] = [];
		this.reservationsHistory.forEach(el => {
			const isExpired = useCartStore().isExpired(Date.now(), el.createdAt);
			if (isExpired) expired.push(el);
			else notExpired.push(el);
		});
		this.reservationsHistory = notExpired;
		clearReservationKeysPersistence(expired.map(el => el.key));
	}

	@Mutation setRestClient(restClient: CompanyRestFactory) {
		this.restClient = restClient;
	}

	@Action ensureRestClient() {
		if (!this.restClient)
			this.setRestClient(new CompanyRestFactory());
		return Promise.resolve(this.restClient as CompanyRestFactory);
	}

	@Action login() {
		return loginPopup();
	}

	@Action signup() {
		return loginPopup("signup");
	}

	@Action async completeUserSignup(data: ISignupParameters) {
		const restClient = await this.ensureRestClient();
		const systemId = useSystemStore().Id;
		const response = await restClient.linkCustomerToCompany(data.companyId, data.privacyData);
		if (response.fetchResponse?.ok || response.error?.data?.Error?.Code === "CustomerExists") {
			this.setIsLinkedToCompany(true);
			await this.syncLinkToSystem({ companyId: data.companyId, systemId });
		}
	}

	@Action async syncLinkToSystem(data: { companyId: number, systemId: number }) {
		const restClient = await this.ensureRestClient();
		const response = await restClient.linkCustomerToSystem(data.companyId, data.systemId);
		this.setIsLinkedToSystem(response.fetchResponse?.ok ?? false);
	}

	@Action async ensureCustomerInfo() {
		if (this.CustomerInfo) return;
		const companyId = useCompanyStore().Id;
		const systemId = useSystemStore().Id;
		const restClient = await this.ensureRestClient();
		const response = await restClient.getCustomer(companyId);

		this.setCustomerInfo(!response.fetchResponse?.ok ? null : response.data);
		this.setIsLinkedToCompany(response.fetchResponse?.ok ?? false);
		const currSystemIsLinked = Boolean(response?.data?.AssociatedCenters?.find(el => el.CenterId === systemId));

		this.setIsLinkedToSystem(currSystemIsLinked);
	}

	@Action async initModuleData(context: IBaseContext) {
		const { result, error } = await tryGetUserSessionTokens();
		const claims = result?.idTokenClaims as IIdTokenClaims | undefined;
		if (result && claims && !error) {
			const tokens = result.idTokenClaims as IIdTokenClaims;
			const Email = tokens.emails[tokens.emails.length - 1];
			const key = await userPersistenceKey({ Email });
			const accessCtx = context.access();
			const [persistenceData] = await resolveMutationContext(accessCtx.get<IUserFull>(key));
			this.setUserData({
				AccessToken: result.accessToken,
				FirstName: claims.given_name,
				Email: claims.emails[claims.emails.length - 1],
				LastName: claims.family_name,
				Phone: claims.extension_Phone,
				AccountInfo: result.account,
				reservationsHistory: persistenceData ? persistenceData.reservationsHistory : []
			});
		} else {
			const accessCtx = context.access();
			const guestUserKey = await userPersistenceKey();
			const [guestData] = await resolveMutationContext(accessCtx.get<IUserFull>(guestUserKey));
			this.setUserData(guestData);
		}
		restUtilsStore.setAccessTokenInfo(result);
	}
}

const moduleName = "user";

let UseStore: UserStoreFactory | null;

function useUserStore() {
	if (UseStore) return UseStore;

	const mod = UseStore = new UserStoreFactory({ store, name: moduleName });

	mod.$watch(state => state.userData, (newUser, oldUser) => {
		if (newUser?.AccessToken !== oldUser?.AccessToken)
			EventsManager.emitUserChanged(newUser, oldUser);
	});
	return mod;
}

export default useUserStore;

async function userPersistenceKey(user?: Pick<IUserFull, "Email"> | null) {
	const guestPersistenceKeyBase = "guest";
	await waitForSystem();
	const systemId = useSystemStore().Id;
	if (user)
		return user.Email;
	else if (useUserStore().isLoggedIn)
		return useUserStore().userData.Email;
	else if (systemId)
		return `${guestPersistenceKeyBase}-${systemId}`;
	else
		return guestPersistenceKeyBase;
}
export const UserModuleInit = usePersistentModule(moduleName, useUserStore(), {
	async onMutation(context) {
		const key = await userPersistenceKey();
		if (!context) return;
		const accessCtx = context.access();
		if (context.mutationsContains("clearPersistedData"))
			return await resolveMutationContext(accessCtx.delete(key));

		await resolveMutationContext(accessCtx.put(useUserStore().userData, key));
	}
});
export async function getClient(flow: AzureADB2CFlow = "policySignUpSignIn") {
	const appStore = useAppStore();
	const clientId = appStore.azure.clientId;
	const authority = appStore.azure.instance;
	const domain = appStore.azure.domain;
	const server = `${authority}/tfp/${domain}`;
	const flowId = appStore.azure[flow];

	return new PublicClientApplication({
		auth: {
			clientId,
			authority: `${server}/${flowId}`,
			knownAuthorities: [authority],
			redirectUri: location.origin,
			navigateToLoginRequestUrl: false
		},
		cache: {
			cacheLocation: "localStorage",
			storeAuthStateInCookie: false
		},
		system: {
			windowHashTimeout: 60000,
			iframeHashTimeout: 60000,
			loadFrameTimeout: 60000
		}
	});
}
function getScopes() {
	return [
		"openid",
		"profile",
		`https://${useAppStore().azure.domain}/${useAppStore().azure.registrationApp}/User.Read`,
		`https://${useAppStore().azure.domain}/${useAppStore().azure.registrationApp}/User.Write`
	];
}
export async function logout() {
	const UserStore = useUserStore();
	if (!UserStore.isLoggedIn) return;
	const msal = await getClient();
	msal.logoutPopup();
	msal.setActiveAccount(null);
	sessionStorage.clear();
	UserStore.clearData();
}
export async function notifyUserSessionExpiredLogout() {
	if (!useUserStore().isSessionExpired()) return Promise.resolve();

	await modalOk([
		BowlerApp.$createElement("div", usei18n().translateKey("weboffers_session_expired")),
		BowlerApp.$createElement("div", usei18n().translateKey("weboffers_session_new"))
	], {
		title: usei18n().translateKey("label_information")
	});
	logout();
}
let _isAskingForPrivacy: Promise<boolean | null> | null = null;
export function notifyUserPrivacyMissing() {
	if (_isAskingForPrivacy) return _isAskingForPrivacy;
	const centerName = useSystemStore().Name;
	const companyId = useCompanyStore().Id;
	const id = "privacy-" + Date.now();
	const route = router.resolve({
		name: policyPage,
		params: {
			...router.currentRoute.params,
			type: PolicyTermType.PrivacyPolicy
		},
		query: router.currentRoute.query
	});
	const urlPrivacyCompany = route.href;

	_isAskingForPrivacy = modalEmpty([
		BowlerApp.$createElement("PrivacyModal", {
			props: {
				privacyLink: urlPrivacyCompany
			},
			on: {
				proceed: async(data: IPrivacyChoice[]) => {
					await useUserStore().completeUserSignup({
						companyId,
						privacyData: {
							privacyChoices: data
						}
					});
					setTimeout(() => toastSuccess(usei18n().translateKey("signup_complete", { centerName })), 1500);
					BowlerApp.$bvModal.hide(id);
				},
				decline: async() => {
					await logout();
					BowlerApp.$bvModal.hide(id);
				}
			}
		})
	],
	{
		title: usei18n().translateKey("signup_modal_title"),
		modalClass: "modal-privacy",
		id
	});
	return _isAskingForPrivacy;
}
export async function syncLoggedUserPrivacy() {
	const companyId = useCompanyStore().Id;
	const systemId = useSystemStore().Id;
	if (!useUserStore().isLoggedIn) return;

	await useUserStore().ensureCustomerInfo();
	if (useUserStore().isLinkedToCompany && useUserStore().acceptedPrivacy && useUserStore().acceptedOrDeclinedOffers) {
		if (useUserStore().isLinkedToSystem) return;

		await useUserStore().syncLinkToSystem({ companyId, systemId });
		return;
	}

	if (router.currentRoute.name !== cartPage) {
		await timeout(1500);
		await notifyUserPrivacyMissing();
	}
}
let _isNotifyingBlockedPopups: Promise<boolean | null> | null = null;
export function notifyUserPopupsBlocked() {
	if (_isNotifyingBlockedPopups) return _isNotifyingBlockedPopups;

	_isNotifyingBlockedPopups = modalWarning([
		BowlerApp.$createElement("div", usei18n().translateKey("common_error_popup_blocked_text"))
	], {
		title: usei18n().translateKey("common_error_popup_blocked_title")
	});
	return _isNotifyingBlockedPopups;
}
export function setAuthenticationResult(msal: PublicClientApplication, result: AuthenticationResult | null) {
	if (result) {
		msal.setActiveAccount(result.account);
		restUtilsStore.setAccessTokenInfo(result);
		const claims = result?.idTokenClaims as IIdTokenClaims | undefined;
		if (result.accessToken && claims) {
			useUserStore().setUserData({
				AccessToken: result.accessToken,
				FirstName: claims.given_name,
				LastName: claims.family_name,
				Email: claims.emails[claims.emails.length - 1],
				Phone: claims.extension_Phone,
				AccountInfo: result.account
			});
		} else useUserStore().clearData();
	} else {
		msal.setActiveAccount(null);
		restUtilsStore.setAccessTokenInfo(null);
		sessionStorage.clear();
		useUserStore().clearData();
		useUserStore().clearPersistedData();
	}
}
export async function loginPopup(onStartAction?: "signup") {
	if (useAppStore().azurePopupWindowIsOpen)
		return azurePopupWindowFocus();

	async function launchIt(flow: AzureADB2CFlow = "policySignUpSignIn") {
		const companyId = useCompanyStore().Id + "";
		const msal = await getClient(flow);
		const LangStore = useLangStore();
		const popupWidth = 530;
		const popupHeight = 830;
		const scopes = getScopes();
		const systemId = useSystemStore().Id + "";
		EventsManager.onceWindowOpen((win, url, target, features) => {
			if (!target?.includes(useAppStore().azure.domain) || !win)
				return;

			setAzurePopupWindow(win);
		});
		return {
			scopes,
			msal,
			resultAsync: resolveAny<AuthenticationResult, IAuthError>(
				msal.loginPopup({
					scopes,
					extraQueryParameters: {
						ui_locales: LangStore.isoCode ?? LangStore.defaultIsoCode,
						companyId,
						systemId,
						openMode: "popup",
						onStartAction: onStartAction ?? ""
					},
					popupWindowAttributes: {
						popupSize: { width: popupWidth, height: popupHeight }
					},
					prompt: "login"
				})
			)
		};
	}

	try {
		const popup = await launchIt();
		const [result, error] = await popup.resultAsync;
		if (error) {
			const ignoredErrors = ["user_cancelled", "interaction_in_progress", "access_denied"];
			if (!ignoredErrors.includes(error.errorCode))
				throw error;
		}

		if (!result) {
			trackUserEvent(EventAI.UserLoginError, { error });
			return;
		}

		setAuthenticationResult(popup.msal, result);
		useAppStore().setLoadingView(true);
		await syncLoggedUserPrivacy();
		useAppStore().setLoadingView(false);
		trackUserEvent(EventAI.UserLoginSuccessful);
	} catch (e) {
		const errorCode = (e as AuthError).errorCode;
		if (errorCode && errorCode === "popup_window_error")
			await notifyUserPopupsBlocked();
	}
}
export async function tryGetUserSessionTokens() {
	const msal = await getClient();
	const scopes = getScopes();
	const [result, error] = await resolveAny<AuthenticationResult, InteractionRequiredAuthError | Error>(
		msal.acquireTokenSilent({ scopes })
	);
	return { result, error, msal };
}

import { trackView } from "@app-utilities/app-insights";
import type { Route } from "vue-router";
import VueRouter from "vue-router";
import type { Position } from "vue-router/types/router";

import guardInit from "./guard";
import BaseRoutes from "./routes/base";
import PolicyTermPagesRoutes from "./routes/policyTerms";
import ReservationRoutes from "./routes/reservations";

interface IScrollPosition {
	x: number,
	y: number
}
const topPosition: IScrollPosition = {
	x: 0, y: 0
};
export const notFoundFallbackViewName = "not-found-fallback";

const router = new VueRouter({
	linkActiveClass: "active",
	mode: "history",
	scrollBehavior: (to: Route, from: Route, savedPosition: Position | void) => {
		return new Promise<Position | IScrollPosition>((resolve) => {
			if (savedPosition)
				resolve(savedPosition);

			setTimeout(() => {
				resolve(topPosition);
			}, 25);
		});
	}
});

BaseRoutes.forEach(el => router.addRoute("Base", el));
ReservationRoutes.forEach(el => router.addRoute("Reservation", el));
PolicyTermPagesRoutes.forEach(el => router.addRoute("PolicyTermPages", el));
router.addRoute("NotFound", {
	path: "/not-found",
	name: "not-found",
	component: () => import("@app-views/service/not-found/not-found.vue")
});
router.addRoute("NotFoundFallback", {
	path: "*",
	name: notFoundFallbackViewName,
	component: () => import("@app-views/service/not-found/not-found.vue")
});

guardInit(router);
router.afterEach(to => {
	if (to.name)
		trackView(to.name, "end", to.fullPath);
});

export default router;

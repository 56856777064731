const appStorageKey = "bowlers_data";

interface IAppData {
	db: { version: number, schema: string }
}
type AppDataSection = keyof IAppData;
interface IAppDataGetter {
	(section: "db"): { version: number, schema: string } | undefined
}
interface IAppDataSetter {
	(section: "db", data: { version: number, schema: string }): void
}

export const getAppData: IAppDataGetter = (key: AppDataSection) => {
	try {
		const store: Partial<IAppData> = JSON.parse(localStorage.getItem(appStorageKey) ?? "{}");
		return store[key] as any;
	} catch (e) {}
};
export const setAppData: IAppDataSetter = (key: AppDataSection, data?: any) => {
	try {
		const store: Partial<IAppData> = JSON.parse(localStorage.getItem(appStorageKey) ?? "{}");

		if (data === null || typeof data === "undefined") delete store[key];
		else store[key] = data;
		localStorage.setItem(appStorageKey, JSON.stringify(store));
	} catch (e) {}
};
export function clearAppData() {
	localStorage.removeItem(appStorageKey);
}

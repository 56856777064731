import { IIdTokenClaims } from "@app-store/user";
import { AuthenticationResult } from "@azure/msal-browser";
import { Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

@Module class RestUtilsModelFactory extends VuexModule {
	accessToken: string | null = null;
	accessTokenExpire: Date | null = null;

	get isAccessTokenValid() {
		if (!this.accessToken || !this.accessTokenExpire) return false;
		return Date.now() < +this.accessTokenExpire;
	}

	@Mutation setAccessTokenInfo(tokenInfo: AuthenticationResult | null) {
		this.accessToken = tokenInfo?.accessToken ?? null;
		const claims = tokenInfo?.idTokenClaims as IIdTokenClaims | undefined;
		this.accessTokenExpire = this.accessToken && claims ? new Date(claims.exp * 1000) : null;
	}
}

const moduleName = "rest-utils";
let RestUtilsStore: RestUtilsModelFactory | null;
function useRestUtilsStore() {
	if (RestUtilsStore) return RestUtilsStore;

	const mod = RestUtilsStore = new RestUtilsModelFactory({ store, name: moduleName });

	return mod;
}

export default useRestUtilsStore;

import useAppStore from "@app-store/app";

let globalPopup: Window | null = null;
export function azurePopupWindowFocus() {
	globalPopup?.focus();
}

export function azurePopupWindowClose() {
	globalPopup?.close();
	globalPopup = null;
	useAppStore().setAzurePopupWindowIsOpen(false);
}

export function setAzurePopupWindow(win: Window) {
	if (globalPopup && win.location.href !== globalPopup.location.href)
		azurePopupWindowClose();

	globalPopup = win;
	useAppStore().setAzurePopupWindowIsOpen(true);

	const checker = {
		id: setTimeout(() => {}),
		next: () => {
			checker.id = setTimeout(() => {
				if (!win.closed) return checker.next();
				clearTimeout(checker.id);

				azurePopupWindowClose();
			}, 200);
		}
	};
	checker.next();
}

export const getPaymentPopup = () => ({
	initPath: `/payment-popup.html?qPopupInit=${Date.now()}`,
	path: "/payment-popup.html"
});

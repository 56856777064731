import useLangStore from "@app-store/lang";
import {
	endOfWeek as dateFnsEndOfWeek,
	format as dateFnsFormat,
	Locale,
	parse,
	startOfWeek as dateFnsStartOfWeek
} from "date-fns";

function toGroups(matches: IterableIterator<RegExpMatchArray>) {
	let groups: string[] = [];
	for (const match of matches)
		groups = [...match];

	return groups;
}

export function stringToDateUTC(datetime: string) {
	function getMonthIndex(n: string) {
		const ni = +n;
		return ni ? ni - 1 : 0;
	}
	const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})(:\d{2})?/g;
	const groups = toGroups(datetime.matchAll(regex));
	if (groups.length) {
		const utc = new Date(
			+groups[1],
			getMonthIndex(groups[2]),
			+groups[3],
			+groups[4],
			+groups[5],
			groups[6] ? +(groups[6].replace(":", "")) : 0
		);
		return utc;
	}
	const dateOnlyRegex = /(\d{4})-(\d{2})-(\d{2})?/g;
	const dateOnlyGroups = toGroups(datetime.matchAll(dateOnlyRegex));
	if (dateOnlyGroups.length) {
		const utc = new Date(
			+dateOnlyGroups[1],
			getMonthIndex(dateOnlyGroups[2]),
			+dateOnlyGroups[3]
		);
		return utc;
	}
}

export function formatOnlyHours(date: Date) {
	const LangStore = useLangStore();
	const dictionary: Locale | undefined = LangStore.libDictionary.get("date-fns");
	const format = dictionary?.formatLong?.time({ width: "short" }) ?? "HH:mm";
	const formattedDate = dateFnsFormat(date, format);
	const timeSep = LangStore.language?.timeSep ?? ":";
	const regexMinutes = new RegExp("\\" + timeSep + "([a-zA-Z0-9_]+)");
	return formattedDate.replace(regexMinutes, "");
}
export function format(date: Date, format: string) {
	const dictionary: Locale | undefined = useLangStore().libDictionary.get("date-fns");
	return dateFnsFormat(date, format, {
		locale: dictionary
	});
}
export function startOfWeek(date: Date) {
	const dictionary: Locale | undefined = useLangStore().libDictionary.get("date-fns");
	return dateFnsStartOfWeek(date, {
		locale: dictionary
	});
}
export function endOfWeek(date: Date) {
	const dictionary: Locale | undefined = useLangStore().libDictionary.get("date-fns");
	return dateFnsEndOfWeek(date, {
		locale: dictionary
	});
}
export function firstDayOfWeek(): number {
	const dictionary: Locale | undefined = useLangStore().libDictionary.get("date-fns");
	return dictionary?.options?.weekStartsOn ?? 0;
}
export function stringToDate(datetime: string, referenceDate = new Date()): Date | undefined {
	const dateUtc = stringToDateUTC(datetime);
	if (dateUtc)
		return dateUtc;

	if (/(\d{2}):(\d{2})/g.test(datetime))
		return parse(datetime, "HH:mm", referenceDate);
}
export function dateKey(date: Date) {
	return dateFnsFormat(date, "yyyy-MM-dd");
}
export function timeKey(date: Date) {
	return dateFnsFormat(date, "HH:mm");
}
export function dateTimeKey(date: Date) {
	return dateFnsFormat(date, "yyyy-MM-dd'T'HH:mm");
}

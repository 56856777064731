import CartRestFactory from "@app-rest/cart";
import { CartSummaryFoodItemData, CartSummaryItemBaseData, ICartItemDetails, ICartItemsByCategory, ICartSummaryRequestBody, ICartSummaryResponse } from "@app-rest/cart.interfaces";
import { IFoodBeverage, IFootwear, IOffer, IReservationExtra } from "@app-rest/reservations.interfaces";
import { getMutationContext, IBaseContext, IPersistentModule, resolveMutationContext, usePersistentModule } from "@app-store/persistent.plugin";
import useReservationStore from "@app-store/reservation";
import { startCartChecker, stopCartChecker } from "@app-store/reservation-flux";
import useSystemStore from "@app-store/system";
import { UserModuleInit } from "@app-store/user";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import EventManager from "@app-utilities/events-manager";
import { persistenceKey } from "@app-utilities/reservation-flux";
import { kebabCase } from "lodash-es";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

export type CartItemCategory = "bowling-experience" | "footwears" | "food-beverage" | "reservation-extras";
export type CartItemSubCategory = "shoes" | "socks";
export interface ICart {
	items: ICartItem[]
	createdAt: Date | null
	isPaid: boolean
}
export interface IModifier {
	GroupId: string,
	Id: string,
	Name: string,
	Price?: number,
	ReferencePriceKeyId?: number
}
export interface IRulesModifier {
	MinQuantity?: number,
	MaxQuantity?: number
}
export interface IModifierGroup {
	Id: string,
	Title: string,
	Rules?: IRulesModifier,
	Modifiers: IModifier[],
	ComputedId: string
}
export interface ICartItem<T = any> {
	ItemId: string | number;
	ItemCompoundKey?: string;
	ItemData: T;
	Category: CartItemCategory;
	SubCategory?: CartItemSubCategory;
	Title: string;
	Description?: string;
	Note?: string;
	Quantity: number;
	UnitPrice: number;
	Modifiers?: IModifier[];
}
export interface ICartItemSetter<T = any> {
	category: CartItemCategory
	subCategory?: CartItemSubCategory
	id: string | number
	compoundKey?: string
	data: T
	quantity?: number
	modifiers?: IModifier[]
	note?: string
	price: number
	title: string
	description: string
	emit?: boolean
}
const moduleName = "cart";
export function cartItemToSetter(item: ICartItem) {
	const setter: ICartItemSetter = {
		id: item.ItemId,
		category: item.Category,
		subCategory: item.SubCategory,
		data: item.ItemData,
		quantity: item.Quantity,
		price: item.UnitPrice,
		title: item.Title ?? "",
		description: item.Description ?? "",
		emit: false,
		...(item.ItemCompoundKey && { compoundKey: item.ItemCompoundKey }),
		...(item.Modifiers && { modifiers: item.Modifiers }),
		...(item.Note && { note: item.Note })
	};
	return setter;
}

@Module class CartStoreFactory extends VuexModule implements IPersistentModule {
	restClient: CartRestFactory | null = null;
	items: ICartItem[] = [];
	createdAt: Date | null = null;
	isPaid = false;
	categoriesOrder: CartItemCategory[] = [
		"bowling-experience",
		"footwears",
		"reservation-extras",
		"food-beverage"
	];

	summaryData: ICartSummaryResponse | null = null;
	maxLife = 3.6e+6; // 1 hour
	get categories() {
		return this.categoriesOrder.filter(
			c => this.items.filter(x => x.Category === c).length > 0
		);
	}

	getItemsQtyById(id: number | string, category: CartItemCategory, subCategory?: CartItemSubCategory) {
		return this.items.filter(item => item.Category === category && item.ItemId === id && item.SubCategory === subCategory)
			.reduce((acc, val) => { return acc + val.Quantity }, 0);
	}

	get getItemById() {
		return (id: number | string, category: CartItemCategory, subCategory?: CartItemSubCategory, compoundKey?: string) => {
			const index = this.findIndexBySetter({ id, category, subCategory, compoundKey });
			if (index === null) return undefined;
			return this.items[index];
		};
	}

	get getItemsInCategory() {
		return (category: CartItemCategory) => this.items.filter(x => x.Category === category);
	}

	get itemsDetails() {
		return this.items
			.map(x => {
				const type = x.Category === "food-beverage"
					? "FoodBeverage"
					: x.Category === "reservation-extras"
						? "Extras"
						: x.Category === "footwears"
							? "ShoesSocks"
							: "WebOffer";
				const modifiers = x.Modifiers?.map(mod => {
					return {
						OriginalId: +mod.Id,
						...(mod.ReferencePriceKeyId && { ReferencePriceKeyId: mod.ReferencePriceKeyId }),
						Name: mod.Name
					};
				});

				const detail: ICartItemDetails = {
					Name: x.ItemData.Name,
					Type: type,
					PriceKeyId: type === "ShoesSocks" ? x.ItemData.PriceKeyId : x.ItemId,
					Quantity: x.Quantity,
					UnitPrice: x.UnitPrice,
					...(modifiers && { Modifiers: modifiers }),
					...(x.Note && { Note: x.Note })
				};
				return detail;
			});
	}

	getItemDetailsByCategory(): ICartItemsByCategory | null {
		const reservationStore = useReservationStore();
		if (!reservationStore.offer) return null;

		const categoryMap: Record<CartItemCategory, keyof ICartItemsByCategory> = {
			"food-beverage": "FoodAndBeverage",
			"reservation-extras": "Extra",
			footwears: "ShoesSocks",
			"bowling-experience": "WebOffer"
		};
		const mapToSummaryItem = (item: ICartItem<any>): CartSummaryItemBaseData | CartSummaryFoodItemData => {
			const Modifiers = item.Modifiers?.map(mod => {
				return {
					OriginalId: Number(mod.Id),
					...(mod.ReferencePriceKeyId && { ReferencePriceKeyId: mod.ReferencePriceKeyId })
				};
			});
			return {
				PriceKeyId: categoryMap[item.Category] === "ShoesSocks" ? item.ItemData.PriceKeyId : item.ItemId,
				Quantity: item.Quantity,
				UnitPrice: item.UnitPrice,
				Note: item.Note ?? "",
				...(Modifiers && { Modifiers })

			};
		};

		const details: ICartItemsByCategory = {
			Extra: [],
			FoodAndBeverage: [],
			ShoesSocks: [],
			WebOffer: {
				Id: reservationStore.offer.OfferId,
				UnitPrice: reservationStore.offer.Total,
				WebOfferTariffId: reservationStore.offer.ItemId
			}
		};

		for (const item of this.items) {
			const category = categoryMap[item.Category];
			switch (category) {
				case "WebOffer":
					break;
				case "FoodAndBeverage":
					details.FoodAndBeverage.push(mapToSummaryItem(item) as CartSummaryFoodItemData);
					break;
				default:
					details[category].push(mapToSummaryItem(item));
					break;
			}
		}

		return details;
	}

	get getTotalInCategory() {
		return (category: CartItemCategory) => this.items
			.filter(x => x.Category === category)
			.map(x => x.UnitPrice * x.Quantity)
			.reduce((acc, val) => acc + val, 0);
	}

	get getItemsInCart() {
		return this.items
			.reduce((acc, item) => acc + item.Quantity, 0);
	}

	get isEmpty() {
		return this.items.length === 0;
	}

	get cartData(): ICart {
		return {
			items: this.items ?? [],
			createdAt: this.createdAt,
			isPaid: this.isPaid
		};
	}

	get isExpired() {
		return (now?: Date | number, createdAt?: Date | number) => {
			if (!this.createdAt) return false;
			if (!now)
				now = Date.now();
			if (!createdAt)
				createdAt = this.createdAt;
			const expireAt = +createdAt + this.maxLife;
			return +now >= expireAt;
		};
	}

	private getTotalFromItems() {
		return this.items.map(x => x.UnitPrice * x.Quantity).reduce((acc, val) => acc + val, 0);
	}

	get totalWithoutTaxes() {
		if (!this.summaryData) return this.getTotalFromItems();

		if (!this.summaryData.TotalItems) return this.summaryData.TotalWithoutTaxes;

		return this.summaryData.TotalItems;
	}

	get deposit() {
		if (!this.summaryData) return 0;

		return this.summaryData.Deposit;
	}

	get fee() {
		if (!this.summaryData) return 0;

		return this.summaryData.Fee;
	}

	get addedTaxes() {
		if (!this.summaryData) return 0;

		return this.summaryData.AddedTaxes;
	}

	get total() {
		if (!this.summaryData) return this.getTotalFromItems();

		return this.summaryData.Total;
	}

	@Mutation clearData() {
		this.items = [];
		this.summaryData = null;
		this.isPaid = false;
		this.createdAt = null;
	}

	@Mutation clearDataInCategory(category: CartItemCategory) {
		const newList = this.items.filter(x => x.Category !== category);
		this.items = newList;
	}

	@Mutation addItem(setter: Omit<ICartItemSetter, "id" | "compoundKey">) {
		this.addSingleItem(setter);
	}

	private addSingleItem(setter: Omit<ICartItemSetter, "id" | "compoundKey">) {
		if (!this.items.length)
			this.createdAt = new Date();

		const setterFull = this.getItemInternals(setter);
		const cartItem: ICartItem = {
			Category: setterFull.category,
			SubCategory: setterFull.subCategory,
			ItemData: setterFull.data,
			ItemId: setterFull.id,
			ItemCompoundKey: setterFull.compoundKey,
			Quantity: setterFull.quantity ?? 1,
			Modifiers: setterFull.modifiers,
			Note: setterFull.note,
			UnitPrice: setterFull.price,
			Title: setterFull.title,
			Description: setterFull.description
		};
		const itemrefIndex = this.findIndexBySetter(setterFull);
		const itemref = itemrefIndex !== null ? this.items[itemrefIndex] : null;
		if (itemref) {
			itemref.Quantity += cartItem.Quantity;
			const qtyAdded = Number(cartItem.Quantity);
			if (setter.emit) {
				EventManager.emitCartItemsAdded([{
					...itemref,
					Quantity: qtyAdded
				}]);
			}
		} else {
			this.items.push(cartItem);
			if (setter.emit)
				EventManager.emitCartItemsAdded([cartItem]);
		}
	}

	@Mutation subItem(setter: Pick<ICartItemSetter, "id" | "compoundKey" | "category" | "subCategory">) {
		const index = this.findIndexBySetter(setter);

		if (index === null || this.items[index].Quantity === 0) return;

		const tmpItems = [...this.items];
		tmpItems[index].Quantity--;
		this.items = tmpItems;
	}

	private findIndexBySetter(setter: Pick<ICartItemSetter, "id" | "compoundKey" | "category" | "subCategory">) {
		const index = setter.compoundKey
			? this.items.findIndex(i => i.ItemId === setter.id
				&& i.ItemCompoundKey
				&& i.ItemCompoundKey === setter.compoundKey)
			: setter.subCategory
				? this.items.findIndex(i => i.ItemId === setter.id
					&& i.Category === setter.category
					&& i.SubCategory === setter.subCategory)
				: this.items.findIndex(i => i.ItemId === setter.id
					&& i.Category === setter.category);
		return index >= 0 ? index : null;
	}

	private getItemInternals(setter: Omit<ICartItemSetter, "id" | "compoundKey">) {
		const id = setter.category === "food-beverage"
			? (setter.data as IFoodBeverage).Id
			: setter.category === "footwears"
				? (
					setter.subCategory === "shoes"
						? (setter.data as IFootwear).PlayerTypeId
						: (
							setter.subCategory === "socks"
								? (setter.data as IFootwear).Name
								: setter.data.PriceKeyId
						)
				)
				: setter.category === "bowling-experience"
					? (setter.data as IOffer).OfferId
					: (setter.data as IReservationExtra).Id;
		let compoundKey: string | undefined;
		if (setter.category === "food-beverage") {
			const fbi = setter.data as IFoodBeverage;
			const fbiMods = setter.modifiers?.map(mod => `${mod.GroupId}-${mod.Id}`).sort((a, b) => a.localeCompare(b)).join(";") ?? "NoMods";
			const fbiNote = setter.note?.trim() ? kebabCase(setter.note) : "NoNote";
			compoundKey = `${fbi.Id}_${fbiMods}_${fbiNote}`;
		}
		if (setter.category === "footwears") {
			const subCat = setter.subCategory;
			const fw = setter.data as IFootwear;
			compoundKey = `${subCat}_${fw.PlayerTypeId ?? "NoPlayerType"}_${fw.Name ?? "NoName"}_${fw.PriceKeyId ?? "NoPriceKey"}`;
		}
		const item: ICartItemSetter = {
			...setter,
			id,
			compoundKey
		};
		return item;
	}

	@Mutation setItems(setters: Omit<ICartItemSetter, "id">[]) {
		this.items = [];
		setters.map(setter => this.addSingleItem(setter));
	}

	@Mutation removeItem(setter: Pick<ICartItemSetter, "id" | "compoundKey" | "category" | "subCategory">) {
		const index = this.findIndexBySetter(setter);

		if (index === null) return;
		this.items.splice(index, 1);
	}

	@Mutation setIsPaid(state: boolean) {
		this.isPaid = state;
	}

	@Mutation removeAllItems() {
		this.items.splice(0, this.items.length);
	}

	@Mutation setSummaryData(data: ICartSummaryResponse) {
		this.summaryData = data;
	}

	@Mutation setCreatedAt(date: Date | null) {
		this.createdAt = date;
	}

	@Mutation setRestClient(restClient: CartRestFactory) {
		this.restClient = restClient;
	}

	@Action ensureRestClient() {
		if (!this.restClient)
			this.setRestClient(new CartRestFactory());
		return Promise.resolve(this.restClient as CartRestFactory);
	}

	@Action async handleCreatedAt(date: Date | null) {
		this.setCreatedAt(date);
		if (date) return startCartChecker();
		else stopCartChecker();
	}

	@Action async syncSummary(data: ICartSummaryRequestBody) {
		const restClient = await this.ensureRestClient();
		const response = await restClient.cartSummary(useSystemStore().Id, data);
		if (response.data) this.setSummaryData(response.data);
	}

	private async _syncWithStore(context: IBaseContext) {
		await UserModuleInit;
		const moduleKey = await persistenceKey();
		if (!moduleKey) return;
		const accessCtx = context.access();
		const [data, error] = await resolveMutationContext(accessCtx.get<ICart>(moduleKey));
		if (error) {
			trackUserEvent(EventAI.IndexedDBFailure, {
				message: "Failed to resolve syncWithStore",
				error,
				moduleName
			});
		}
		const items = data?.items ?? [];
		this.setItems(items.map(cartItemToSetter));
		await this.handleCreatedAt(data?.createdAt ?? null);
	}

	@Action initModuleData(context: IBaseContext) {
		return this._syncWithStore(context);
	}

	@Action syncWithStore() {
		const ctx = getMutationContext(moduleName);
		if (!ctx) return Promise.resolve();
		return this._syncWithStore(ctx);
	}
}

let CartStore : CartStoreFactory | null;

function useCartStore() {
	if (CartStore) return CartStore;

	const mod = CartStore = new CartStoreFactory({ name: moduleName, store });

	return mod;
}

export default useCartStore;

export const CartModuleInit = usePersistentModule(moduleName, useCartStore(), {
	async onMutation(context) {
		if (!context || useReservationStore().isReservationConfirmed) return;
		const CartStore = useCartStore();
		const moduleKey = await persistenceKey();
		if (!moduleKey) return;
		if (context.mutationsContains("clearData") || context.mutationsContains("clearPersistedData")) {
			const accessCtx = context.access();
			return await resolveMutationContext(accessCtx.delete(moduleKey));
		}

		if (!CartStore.cartData.items.length)
			return;

		if (!context.mutationsContains("ensureRestClient") && !context.mutationsContains("setSummaryData")) {
			const accessCtx = context.access();
			await resolveMutationContext(accessCtx.put(CartStore.cartData, moduleKey));
		}
	}
});

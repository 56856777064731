import ButtonClose from "@app-components/button-close/button-close.vue";
import { useResponsiveUtilities } from "@app-composables/responsive";
import { computed, defineComponent, onMounted, onUnmounted } from "vue";

export default defineComponent({
	name: "FieldTooltip",
	components: {
		ButtonClose
	},
	props: {
		open: { type: Boolean, default: true },
		title: { type: String, default: "" },
		hideHeader: { type: Boolean, default: false },
		hideTitle: { type: Boolean, default: false },
		hideClose: { type: Boolean, default: false },
		hideArrow: { type: Boolean, default: false },
		goDownDisabled: { type: Boolean, default: false }
	},
	emits: ["click-close"],
	setup(props, { emit }) {
		const responsiveUtilities = useResponsiveUtilities();
		const isDown = computed(() => {
			return responsiveUtilities.isSmall.value;
		});
		const isUp = computed(() => {
			return (responsiveUtilities.isMedium.value || responsiveUtilities.isBig.value);
		});
		const onCloseClick = () => {
			emit("click-close");
		};
		const classObject = computed(() => {
			return {
				"is-open": props.open,
				"hide-header": props.hideHeader,
				"hide-title": props.hideTitle,
				"hide-close": props.hideClose,
				"hide-arrow": props.hideArrow,
				"will-go-down": !props.goDownDisabled,
				"go-down-disabled": props.goDownDisabled,
				"is-up": props.goDownDisabled ? true : isUp.value,
				"is-down": props.goDownDisabled
					? false
					: responsiveUtilities.isSmall.value
			};
		});
		onMounted(() => {
			responsiveUtilities.init();
		});
		onUnmounted(() => {
			responsiveUtilities.destroy();
		});
		return {
			isDown,
			isUp,
			onCloseClick,
			classObject
		};
	}
});

import type { IPrivacyChoice } from "@app-store/user";

import type { ICenterBase } from "./entities.interfaces";

export interface ICompany {
	Id: number
	Language: string
	Name: string
}
export interface ICompanyLocationGroup {
	GroupName: string;
	GroupItems: ICompanyLocation[];
}
export type ICompanyLocation = Pick<ICenterBase, "Id" | "Name">
export interface IPrivacyData {
	Name: PolicyType,
	Version: number
}
export enum PolicyType {
	TermsAndConditions = "TermsAndConditions",
	ReceiveOffers = "ReceiveOffers",
	ReceiveFromOtherCompanies = "ReceiveFromOtherCompanies"
}
export interface SignupBody {
	privacyChoices: IPrivacyChoice[];
}

export type ReceiveOffersFlags = "OK" | "KO";

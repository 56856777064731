<xliff xmlns="urn:oasis:names:tc:xliff:document:2.0" version="2.0" srcLang="en-US">
	<file id="bowlers">
		<group id="labels">
			<unit id="label_ok">
				<segment>
					<source>Ok</source>
				</segment>
			</unit>
			<unit id="label_or">
				<segment>
					<source>Or</source>
				</segment>
			</unit>
			<unit id="label_cancel">
				<segment>
					<source>Cancel</source>
				</segment>
			</unit>
			<unit id="label_close">
				<segment>
					<source>Close</source>
				</segment>
			</unit>
			<unit id="label_yes">
				<segment>
					<source>Yes</source>
				</segment>
			</unit>
			<unit id="label_no">
				<segment>
					<source>No</source>
				</segment>
			</unit>
			<unit id="label_next">
				<segment>
					<source>Next</source>
				</segment>
			</unit>
			<unit id="label_back">
				<segment>
					<source>Back</source>
				</segment>
			</unit>
			<unit id="label_confirm">
				<segment>
					<source>Confirm</source>
				</segment>
			</unit>
			<unit id="label_check_availability">
				<segment>
					<source>Check availability</source>
				</segment>
			</unit>
			<unit id="label_add">
				<segment>
					<source>Add</source>
				</segment>
			</unit>
			<unit id="label_update">
				<segment>
					<source>Update</source>
				</segment>
			</unit>
			<unit id="label_today">
				<segment>
					<source>Today</source>
				</segment>
			</unit>
			<unit id="label_location">
				<segment>
					<source>Location</source>
				</segment>
			</unit>
			<unit id="label_date">
				<segment>
					<source>Date</source>
				</segment>
			</unit>
			<unit id="label_time">
				<segment>
					<source>Time</source>
				</segment>
			</unit>
			<unit id="label_bowler">
				<segment>
					<source>Bowler</source>
				</segment>
			</unit>
			<unit id="label_bowlers">
				<segment>
					<source>Bowlers</source>
				</segment>
			</unit>
			<unit id="label_player">
				<segment>
					<source>Player</source>
				</segment>
			</unit>
			<unit id="label_players">
				<segment>
					<source>Players</source>
				</segment>
			</unit>
			<unit id="label_player_number">
				<segment>
					<source>Player {{number}}</source>
				</segment>
			</unit>
			<unit id="label_plus">
				<segment>
					<source>Plus</source>
				</segment>
			</unit>
			<unit id="label_sub">
				<segment>
					<source>Sub</source>
				</segment>
			</unit>
			<unit id="label_hour">
				<segment>
					<source>Hour</source>
				</segment>
			</unit>
			<unit id="label_hours">
				<segment>
					<source>Hours</source>
				</segment>
			</unit>
			<unit id="label_minutes">
				<segment>
					<source>Minutes</source>
				</segment>
			</unit>
			<unit id="label_per_player">
				<segment>
					<source>per player</source>
				</segment>
			</unit>
			<unit id="label_game">
				<segment>
					<source>Game</source>
				</segment>
			</unit>
			<unit id="label_games">
				<segment>
					<source>Games</source>
				</segment>
			</unit>
			<unit id="label_lane">
				<segment>
					<source>Lane</source>
				</segment>
			</unit>
			<unit id="label_lanes">
				<segment>
					<source>Lanes</source>
				</segment>
			</unit>
			<unit id="label_available">
				<segment>
					<source>Available</source>
				</segment>
			</unit>
			<unit id="label_bowling">
				<segment>
					<source>Bowling</source>
				</segment>
			</unit>
			<unit id="label_extras">
				<segment>
					<source>Extras</source>
				</segment>
			</unit>
			<unit id="label_pair">
				<segment>
					<source>Pair</source>
				</segment>
			</unit>
			<unit id="label_pair_one_qty">
				<segment>
					<source>1 Pair</source>
				</segment>
			</unit>
			<unit id="label_pair_qty">
				<segment>
					<source>{{qty}} Pairs</source>
				</segment>
			</unit>
			<unit id="label_login_and_proceed">
				<segment>
					<source>Login and proceed</source>
				</segment>
			</unit>
			<unit id="label_login_or_register">
				<segment>
					<source>Login or register</source>
				</segment>
			</unit>
			<unit id="label_decline_and_logout">
				<segment>
					<source>Decline and Logout</source>
				</segment>
			</unit>
			<unit id="label_proceed_as_guests">
				<segment>
					<source>Proceed as guest</source>
				</segment>
			</unit>
			<unit id="label_proceed">
				<segment>
					<source>Proceed</source>
				</segment>
			</unit>
			<unit id="label_proceed_pay">
				<segment>
					<source>Proceed and pay</source>
				</segment>
			</unit>
			<unit id="label_summary">
				<segment>
					<source>Summary</source>
				</segment>
			</unit>
			<unit id="label_order_summary">
				<segment>
					<source>Order Summary</source>
				</segment>
			</unit>
			<unit id="label_order">
				<segment>
					<source>Order</source>
				</segment>
			</unit>
			<unit id="label_total">
				<segment>
					<source>Total</source>
				</segment>
			</unit>
			<unit id="label_subtotal">
				<segment>
					<source>Subtotal</source>
				</segment>
			</unit>
			<unit id="label_total_wo_taxes">
				<segment>
					<source>Total (w/o taxes)</source>
				</segment>
			</unit>
			<unit id="label_taxes">
				<segment>
					<source>Taxes</source>
				</segment>
			</unit>
			<unit id="label_deposit_percentage">
				<segment>
					<source>Deposit required ({{qty}}%)</source>
				</segment>
			</unit>
			<unit id="label_deposit">
				<segment>
					<source>Deposit required</source>
				</segment>
			</unit>
			<unit id="label_center_info">
				<segment>
					<source>Center Info</source>
				</segment>
			</unit>
			<unit id="label_account">
				<segment>
					<source>Account</source>
				</segment>
			</unit>
			<unit id="label_cart">
				<segment>
					<source>Cart</source>
				</segment>
			</unit>
			<unit id="label_attention">
				<segment>
					<source>Attention</source>
				</segment>
			</unit>
			<unit id="label_order_details">
				<segment>
					<source>Order Details</source>
				</segment>
			</unit>
			<unit id="label_save">
				<segment>
					<source>Save</source>
				</segment>
			</unit>
			<unit id="label_booking_number">
				<segment>
					<source>Booking number</source>
				</segment>
			</unit>
			<unit id="label_booking_fee">
				<segment>
					<source>Booking fee</source>
				</segment>
			</unit>
			<unit id="label_log_out">
				<segment>
					<source>Log Out</source>
				</segment>
			</unit>
			<unit id="label_logout">
				<segment>
					<source>Logout</source>
				</segment>
			</unit>
			<unit id="label_login">
				<segment>
					<source>Login</source>
				</segment>
			</unit>
			<unit id="label_book">
				<segment>
					<source>Book</source>
				</segment>
			</unit>
			<unit id="label_sold_out">
				<segment>
					<source>Sold Out</source>
				</segment>
			</unit>
			<unit id="label_information">
				<segment>
					<source>Information</source>
				</segment>
			</unit>
			<unit id="label_bumpers">
				<segment>
					<source>Bumpers</source>
				</segment>
			</unit>
			<unit id="label_name">
				<segment>
					<source>Name</source>
				</segment>
			</unit>
			<unit id="label_shoes">
				<segment>
					<source>Shoe Size</source>
				</segment>
			</unit>
			<unit id="label_category">
				<segment>
					<source>Category</source>
				</segment>
			</unit>
			<unit id="label_size">
				<segment>
					<source>Size</source>
				</segment>
			</unit>
			<unit id="label_continue">
				<segment>
					<source>Continue</source>
				</segment>
			</unit>
			<unit id="label_start_new">
				<segment>
					<source>Start New</source>
				</segment>
			</unit>
			<unit id="label_expand">
				<segment>
					<source>Expand</source>
				</segment>
			</unit>
			<unit id="label_privacy_policy">
				<segment>
					<source>Privacy policy</source>
				</segment>
			</unit>
			<unit id="label_accept">
				<segment>
					<source>Accept</source>
				</segment>
			</unit>
			<unit id="label_do_not_accept">
				<segment>
					<source>Do not accept</source>
				</segment>
			</unit>
			<unit id="label_notes">
				<segment>
					<source>Notes</source>
				</segment>
			</unit>
			<unit id="label_read_more">
				<segment>
					<source>Read More</source>
				</segment>
			</unit>
			<unit id="label_read_less">
				<segment>
					<source>Read Less</source>
				</segment>
			</unit>
			<unit id="label_keep_my_offer">
				<segment>
					<source>Keep my offer</source>
				</segment>
			</unit>
			<unit id="label_empty_cart">
				<segment>
					<source>Empty cart</source>
				</segment>
			</unit>
			<unit id="label_add_now">
				<segment>
					<source>Add now</source>
				</segment>
			</unit>
			<unit id="label_checkout">
				<segment>
					<source>checkout</source>
				</segment>
			</unit>
		</group>
		<group id="common">
			<unit id="common_error_title_oops_not_found">
				<segment>
					<source>Oops, that page can't be found.</source>
				</segment>
			</unit>
			<unit id="common_error_message_oops_not_found">
				<segment>
					<source>It looks like nothing was found at this location.</source>
				</segment>
			</unit>
			<unit id="common_error_message_check_url_try_again">
				<segment>
					<source>Please, check the URL and try again.</source>
				</segment>
			</unit>
			<unit id="common_error_resource_not_found">
				<segment>
					<source>Resource not found</source>
				</segment>
			</unit>
			<unit id="common_error_something_went_wrong">
				<segment>
					<source>Something Went Wrong</source>
				</segment>
			</unit>
			<unit id="common_required_field_error">
				<segment>
					<source>This field is required</source>
				</segment>
			</unit>
			<unit id="common_try_later_contact_bowl_center">
				<segment>
					<source>Please try again later or contact the bowling center</source>
				</segment>
			</unit>
			<unit id="common_not_possible_process_payment">
				<segment>
					<source>Sorry, at the moment it is not possible to process payments.</source>
				</segment>
			</unit>
			<unit id="common_tech_paym_error">
				<segment>
					<source>Technical payment error</source>
				</segment>
			</unit>
			<unit id="common_tech_payment_failed">
				<segment>
					<source>Payment failed</source>
				</segment>
			</unit>
			<unit id="common_unable_process_payment">
				<segment>
					<source>Unable to process the payment.</source>
				</segment>
			</unit>
			<unit id="common_unable_process_payment_at_this_time">
				<segment>
					<source>We are sorry but your payment could not be processed at this time. Please try again later or contact the bowling center.</source>
				</segment>
			</unit>
			<unit id="common_please_verify_payment_data">
				<segment>
					<source>Please verify that your credit card data is correct, try again with another credit card or contact the bowling center to make your reservation</source>
				</segment>
			</unit>
			<unit id="common_accept_privacy_policy">
				<segment>
					<source>I accept the Privacy Policy</source>
				</segment>
			</unit>
			<unit id="common_would_receive_offers_email_sms">
				<segment>
					<source>Would you like to receive great offers, discounts and news by email, phone and SMS?</source>
				</segment>
			</unit>
			<unit id="common_terms_conditions">
				<segment>
					<source>Terms and Conditions</source>
				</segment>
			</unit>
			<unit id="common_hi_logged_user">
				<segment>
					<source>Hi, {{userName}}</source>
				</segment>
			</unit>
			<unit id="common_my_account">
				<segment>
					<source>My Account</source>
				</segment>
			</unit>
			<unit id="common_cookie_policy">
				<segment>
					<source>Cookie Policy</source>
				</segment>
			</unit>
			<unit id="common_privacy_policy">
				<segment>
					<source>Privacy Policy</source>
				</segment>
			</unit>
			<unit id="common_terms_and_conditions">
				<segment>
					<source>Terms and Conditions</source>
				</segment>
			</unit>
			<unit id="common_website_terms_of_use">
				<segment>
					<source>Web Site Terms of Use</source>
				</segment>
			</unit>
			<unit id="common_error_modal_something_went_wrong">
				<segment>
					<source>Oops! Something Went Wrong</source>
				</segment>
			</unit>
			<unit id="common_error_modal_page_does_not_work">
				<segment>
					<source>This page does not seem to work correctly at the moment.</source>
				</segment>
			</unit>
			<unit id="common_error_modal_message_please_close_this_window_to_refresh">
				<segment>
					<source>Please, close this window to auto-refresh the page and try again.</source>
				</segment>
			</unit>
			<unit id="common_error_modal_message_please_close_this_window_to_start_a_new_reservation">
				<segment>
					<source>Please, close this window to start a new reservation</source>
				</segment>
			</unit>
			<unit id="common_error_unsupported_browser_title">
				<segment>
					<source>Browser not supported</source>
				</segment>
			</unit>
			<unit id="common_error_unsupported_browser_text">
				<segment>
					<source>Your web browser is not supported or is not updated.</source>
				</segment>
			</unit>
			<unit id="common_error_unsupported_browser_hint">
				<segment>
					<source>Please try one of the following options and make sure it is updated.</source>
				</segment>
			</unit>
			<unit id="common_error_unsupported_browser_chrome">
				<segment>
					<source>Chrome</source>
				</segment>
			</unit>
			<unit id="common_error_unsupported_browser_firefox">
				<segment>
					<source>Firefox</source>
				</segment>
			</unit>
			<unit id="common_error_unsupported_browser_safari">
				<segment>
					<source>Safari</source>
				</segment>
			</unit>
			<unit id="common_error_unsupported_browser_edge">
				<segment>
					<source>Edge</source>
				</segment>
			</unit>
			<unit id="common_error_disabled_features_title">
				<segment>
					<source>Cookies are disabled</source>
				</segment>
			</unit>
			<unit id="common_error_disabled_features_text">
				<segment>
					<source>Please check your browser's "Privacy and Security" settings and make sure cookies are enabled.</source>
				</segment>
			</unit>
			<unit id="common_error_system_is_processing_your_request">
				<segment>
					<source>The system is processing your request, please try again in a minute.</source>
				</segment>
			</unit>
			<unit id="common_error_popup_blocked_title">
				<segment>
					<source>Pop-ups blocked</source>
				</segment>
			</unit>
			<unit id="common_error_popup_blocked_text">
				<segment>
					<source>Pop-ups were blocked on this page. Allow pop-ups in order to complete your registration.</source>
				</segment>
			</unit>
			<unit id="common_error_popup_blocked_text_generic">
				<segment>
					<source>Pop-ups were blocked on this page. Allow pop-ups in order to proceed.</source>
				</segment>
			</unit>
			<unit id="common_want_to_add_change_something_write_here">
				<segment>
					<source>Want to add/change something? Write it here...</source>
				</segment>
			</unit>
			<unit id="common_pick_as_many_as_you_like">
				<segment>
					<source>Pick as many as you like</source>
				</segment>
			</unit>
			<unit id="common_pick_qty_required">
				<segment>
					<source>Pick {{qty}} (required)</source>
				</segment>
			</unit>
			<unit id="common_pick_at_least_qty_required">
				<segment>
					<source>Pick at least {{qty}} (required)</source>
				</segment>
			</unit>
			<unit id="common_pick_qty_if_you_like">
				<segment>
					<source>Pick {{qty}} (if you like)</source>
				</segment>
			</unit>
			<unit id="common_pick_up_to_qty_if_you_like">
				<segment>
					<source>Pick up to {{qty}} (if you like)</source>
				</segment>
			</unit>
			<unit id="common_pick_at_least_min_and_up_max_required">
				<segment>
					<source>Pick at least {{qtyMin}} and up to {{qtyMax}} (required)</source>
				</segment>
			</unit>
			<unit id="common_changing_offer">
				<segment>
					<source>Changing offer</source>
				</segment>
			</unit>
			<unit id="common_change_offer_items_currently_in_cart_will_removed">
				<segment>
					<source>Please note that if you change your offer, all the items that are currently in your cart will be removed.</source>
				</segment>
			</unit>
		</group>
		<group id="homepage">
			<unit id="choose_your_location">
				<segment>
					<source>Choose your location</source>
				</segment>
			</unit>
		</group>
		<group id="reservation">
			<unit id="modify_date">
				<segment>
					<source>Modify date</source>
				</segment>
			</unit>
			<unit id="choose_time">
				<segment>
					<source>Choose time</source>
				</segment>
			</unit>
			<unit id="only_one_available">
				<segment>
					<source>Only one available</source>
				</segment>
			</unit>
			<unit id="only_x_available">
				<segment>
					<source>Only {{qty}} available</source>
				</segment>
			</unit>
			<unit id="shoes_and_socks">
				<segment>
					<source>Shoes &amp; Socks</source>
				</segment>
			</unit>
			<unit id="food_and_beverage">
				<segment>
					<source>Food &amp; Beverage</source>
				</segment>
			</unit>
			<unit id="reservation_first_order_agree_terms">
				<segment>
					<source>By placing a reservation through our website, you agree to the terms and conditions.</source>
				</segment>
			</unit>
			<unit id="reservation_order_recap">
				<segment>
					<source>On {{date}} at {{hour}}</source>
				</segment>
			</unit>
			<unit id="reservation_bowlers_details">
				<segment>
					<source>Bowlers Details</source>
				</segment>
			</unit>
			<unit id="reservation_already_confirmed">
				<segment>
					<source>Your reservation has already been confirmed, please check your email for your reservation details.</source>
				</segment>
			</unit>
			<unit id="reservation_bowlers_missing_info_title">
				<segment>
					<source>Players' Details</source>
				</segment>
			</unit>
			<unit id="reservation_bowlers_missing_info">
				<segment>
					<source>You can add all the players' details now and speed up your check in.</source>
				</segment>
			</unit>
			<unit id="reservation_bowlers_players_info_added">
				<segment>
					<source>{{playersAdded}}/{{totalPlayers}} Players' name added</source>
				</segment>
			</unit>
			<unit id="reservation_bowlers_shoe_sizes_added">
				<segment>
					<source>{{shoesAdded}}/{{totalShoes}} Shoe sizes added</source>
				</segment>
			</unit>
		</group>
		<group id="cart">
			<unit id="in_your_cart">
				<segment>
					<source>In your cart: {{qty}}</source>
				</segment>
			</unit>
			<unit id="cart_your_order">
				<segment>
					<source>Your order</source>
				</segment>
			</unit>
			<unit id="cart_your_account">
				<segment>
					<source>Your account info</source>
				</segment>
			</unit>
			<unit id="cart_your_contact">
				<segment>
					<source>Your contact info</source>
				</segment>
			</unit>
			<unit id="cart_your_information">
				<segment>
					<source>Your Information</source>
				</segment>
			</unit>
			<unit id="cart_register_account">
				<segment>
					<source>Register with {{system}}</source>
				</segment>
			</unit>
			<unit id="cart_welcome">
				<segment>
					<source>Welcome!</source>
				</segment>
			</unit>
			<unit id="cart_welcome_back">
				<segment>
					<source>Welcome back {{user}}!</source>
				</segment>
			</unit>
			<unit id="cart_item_added">
				<segment>
					<source>1 item added</source>
				</segment>
			</unit>
			<unit id="cart_items_added">
				<segment>
					<source>{{qty}} items added</source>
				</segment>
			</unit>
			<unit id="cart_is_empty">
				<segment>
					<source>Your cart is empty</source>
				</segment>
			</unit>
			<unit id="cart_balance_due_upon_arrival">
				<segment>
					<source>balance due upon arrival</source>
				</segment>
			</unit>
			<unit id="cart_guest_modal_proceed_as_guest">
				<segment>
					<source>Proceed as guest</source>
				</segment>
			</unit>
			<unit id="cart_guest_modal_description">
				<segment>
					<source>To book as a non-registered guest, please provide a reference name for the group and the data strictly needed to manage your reservation.</source>
				</segment>
			</unit>
			<unit id="cart_guest_modal_login_advantages">
				<segment>
					<source>To enjoy the convenience and benefits of being a registered member</source>
				</segment>
			</unit>
			<unit id="cart_thankyou_reservation">
				<segment>
					<source>Thank you for your reservation!</source>
				</segment>
			</unit>
			<unit id="cart_thankyou_reservation_recap_info_1_bowlcat">
				<segment>
					<source>You are confirmed for {{bowler}} on {{date}} at {{time}} at {{centerName}}.</source>
				</segment>
			</unit>
			<unit id="cart_thankyou_reservation_recap_info_more_bowlcat">
				<segment>
					<source>You are confirmed for {{bowlers}} and {{lastbowler}} on {{date}} at {{time}} at {{centerName}}.</source>
				</segment>
			</unit>
			<unit id="cart_thankyou_reservation_amount_paid">
				<segment>
					<source>Amount paid:</source>
				</segment>
			</unit>
			<unit id="cart_thankyou_reservation_balance_due">
				<segment>
					<source>Balance due:</source>
				</segment>
			</unit>
			<unit id="cart_confirm_remove_item_from_cart">
				<segment>
					<source>Are you sure you want to remove it from the cart?</source>
				</segment>
			</unit>
			<unit id="cart_confirm_remove_items_from_cart">
				<segment>
					<source>Are you sure you want to remove these items from the cart?</source>
				</segment>
			</unit>
			<unit id="cart_thankyou_reservation_pdf_not_available">
				<segment>
					<source>File not available. Soon we will send you a PDF file with all the details of your reservation.</source>
				</segment>
			</unit>
			<unit id="cart_your_session_has_timed_out">
				<segment>
					<source>Oops, your session has timed out</source>
				</segment>
			</unit>
			<unit id="cart_your_reservation_id_was_canceled">
				<segment>
					<source>You reservation ID ({{reservationKey}}) was canceled. To make a new reservation, close this page and start again.</source>
				</segment>
			</unit>
			<unit id="cart_if_your_card_was_charged">
				<segment>
					<source>If your card was charged, we will issue a refund.</source>
				</segment>
			</unit>
			<unit id="cart_looks_like_this_session_has_expired">
				<segment>
					<source>Looks like this session has expired. To make a new reservation, close this page and start again.</source>
				</segment>
			</unit>
			<unit id="cart_reservation_request_failed">
				<segment>
					<source>Reservation request failed</source>
				</segment>
			</unit>
			<unit id="cart_sorry_our_online_booking_service_is_unavailable">
				<segment>
					<source>Sorry, our online booking service is temporarily unavailable and we could not complete your request</source>
				</segment>
			</unit>
		</group>
		<group id="weboffers">
			<unit id="weboffers_selection_not_available">
				<segment>
					<source>It looks like your selection is no longer available.</source>
				</segment>
			</unit>
			<unit id="weboffers_close_to_check_available">
				<segment>
					<source>Close this window to check the current availability.</source>
				</segment>
			</unit>
			<unit id="weboffers_session_expired">
				<segment>
					<source>Your session has timed out.</source>
				</segment>
			</unit>
			<unit id="weboffers_reservation_expired">
				<segment>
					<source>Your reservation is expired.</source>
				</segment>
			</unit>
			<unit id="weboffer_reservation_left_title">
				<segment>
					<source>Continue from where you left off</source>
				</segment>
			</unit>
			<unit id="weboffers_reservation_left">
				<segment>
					<source>It looks like you have previously started a reservation but have not completed it:</source>
				</segment>
			</unit>
			<unit id="weboffers_session_new">
				<segment>
					<source>Enter your credentials to sign in.</source>
				</segment>
			</unit>
			<unit id="weboffers_reservation_new">
				<segment>
					<source>Close this window to start a new reservation.</source>
				</segment>
			</unit>
			<unit id="weboffers_reservation_new_question">
				<segment>
					<source>Would you like to continue with the current reservation or start a new one?</source>
				</segment>
			</unit>
			<unit id="weboffers_no_matching_offers">
				<segment>
					<source>No Matching Offers</source>
				</segment>
			</unit>
			<unit id="weboffers_no_results">
				<segment>
					<source>We're sorry, there are no matching offers for your selection. Please try another date or time.</source>
				</segment>
			</unit>
			<unit id="weboffers_reservation_time">
				<segment>
					<source>Reservation time {{reservationTime}}</source>
				</segment>
			</unit>
			<unit id="weboffers_you_are_making_reservation">
				<segment>
					<source>You are making a reservation for: {{reservationTime}}</source>
				</segment>
			</unit>
			<unit id="weboffers_do_you_want_to_continue">
				<segment>
					<source>Do you want to continue?</source>
				</segment>
			</unit>
		</group>
		<group id="forms">
			<unit id="form_first_name">
				<segment>
					<source>First Name</source>
				</segment>
			</unit>
			<unit id="form_last_name">
				<segment>
					<source>Last Name</source>
				</segment>
			</unit>
			<unit id="form_reference_name">
				<segment>
					<source>Reference/Name</source>
				</segment>
			</unit>
			<unit id="form_email">
				<segment>
					<source>Email</source>
				</segment>
			</unit>
			<unit id="form_phone">
				<segment>
					<source>Mobile Phone</source>
				</segment>
			</unit>
			<unit id="form_invalid_field">
				<segment>
					<source>Invalid field</source>
				</segment>
			</unit>
			<unit id="form_invalid_format">
				<segment>
					<source>Invalid format</source>
				</segment>
			</unit>
			<unit id="form_text_to_long">
				<segment>
					<source>Text too long</source>
				</segment>
			</unit>
		</group>
		<group id="modals">
			<unit id="modal_select_shoe_size_title">
				<segment>
					<source>Select Shoe Size</source>
				</segment>
			</unit>
		</group>
		<group id="maintenance_mode">
			<unit id="title_maintenance_mode">
				<segment>
					<source>Service Temporarily unavailable</source>
				</segment>
			</unit>
			<unit id="description_maintenance_mode">
				<segment>
					<source>Our online booking service is temporarily unavailable. We are working to restore it.</source>
				</segment>
			</unit>
			<unit id="try_again_maintenance_mode">
				<segment>
					<source>Please try again later or call the bowling center to book a lane.</source>
				</segment>
			</unit>
			<unit id="alt_image_maintenance_mode">
				<segment>
					<source>image maintenance mode</source>
				</segment>
			</unit>
		</group>
		<group id="signup">
			<unit id="signup_modal_title">
				<segment>
					<source>Complete Registration</source>
				</segment>
			</unit>
			<unit id="signup_modal_text">
				<segment>
					<source>To improve your experience with the {{centerName}} website, we ask you to share your Bowl QAMF account information with us.</source>
				</segment>
			</unit>
			<unit id="signup_privacy_text">
				<segment>
					<source>Please review and accept the {{centerName}} Privacy Policy to proceed.</source>
				</segment>
			</unit>
			<unit id="signup_complete">
				<segment>
					<source>Registration complete! Thank you for joining {{centerName}}!</source>
				</segment>
			</unit>
		</group>
	</file>
</xliff>

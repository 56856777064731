import useConnector from "@app-connector";
import useAppStore from "@app-store/app";
import syncSettingsFromBE from "@app-utilities/api-app";
import { setupAppInsights } from "@app-utilities/app-insights";

async function resolveAppContext(env: string, ch: string) {
	const { initAppSettings } = useConnector();

	await initAppSettings({
		launchModeResolver() {
			return Promise.resolve(__LAUNCH_MODE__);
		},
		versionResolver() {
			return Promise.resolve(__VERSION__);
		},
		async settingsResolver() {
			const environment = env === "Develop" || env === "Local"
				? "development"
				: (env).toLocaleLowerCase();
			const channel = (ch).toLocaleLowerCase();

			const contextFileUrl = __LAUNCH_MODE__ === "Standard"
				? "/context.json"
				: `/settings/${environment}-${channel}.json`;
			const response = await fetch(contextFileUrl, { method: "GET", cache: "no-cache" })
				.then((data) => data.json())
				.catch((error) => {
					console.error(error);
				});

			return response;
		}
	});
}

export default async function boot() {
	const appStore = useAppStore();

	const appInstanceData = await syncSettingsFromBE();

	if (!appInstanceData) throw new Error("Missing Instance Data on Boot");

	await resolveAppContext(appStore.env, appStore.channel);

	const { useAppSettings } = useConnector();
	const appSettings = useAppSettings();
	// FIXME: add a local task to copy context file inside wwwroot of the project
	appStore.updateData({
		...appInstanceData,
		connectionString: appStore.launchMode === "DevelopAndTestingSession"
			? appInstanceData.connectionString
			: appSettings.appInsightsConnString,
		website: appStore.launchMode === "DevelopAndTestingSession"
			? appInstanceData.website
			: appSettings.bowlerWebSite,
		websiteBeta: appStore.launchMode === "DevelopAndTestingSession"
			? appInstanceData.websiteBeta
			: appSettings.bowlerWebSiteBeta
	});
	setupAppInsights({
		connectionString: appStore.connectionString,
		maxAjaxCallsPerView: -1,
		disableExceptionTracking: true,
		disableTelemetry: appStore.launchMode === "DevelopAndTestingSession"
	});
}

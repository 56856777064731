import { useRoute } from "@app-composables/router";
import usei18n from "@app-i18n/index";
import { IPolicyTermMenu, PolicyTermType } from "@app-rest/common.interfaces";
import { policyPage, policyTermPage } from "@app-router/routes/policyTerms";
import useCompanyStore from "@app-store/company";
import useLangStore from "@app-store/lang";
import useSystemStore from "@app-store/system";
import { BNavbar, BNavbarNav, BNavItem, BOverlay } from "bootstrap-vue";
import { kebabCase } from "lodash-es";
import { computed, defineComponent, onMounted, reactive } from "vue";

export default defineComponent({
	name: "FooterMenu",
	components: {
		BNavbar,
		BNavbarNav,
		BNavItem,
		BOverlay
	},
	props: {},
	setup() {
		const hasMinimumData = computed(() => {
			return useLangStore().isoCode && useCompanyStore().Id;
		});
		const flags = reactive({
			loading: true
		});
		const cookiePolicy = reactive<IPolicyTermMenu>({
			IsEnabled: true,
			Type: PolicyTermType.CookiePolicy,
			UrlLabel: ""
		});
		const defaultLabelVoices = (type: PolicyTermType) => {
			switch (type) {
				case PolicyTermType.CookiePolicy:
					return usei18n().translateKey("common_cookie_policy");
				case PolicyTermType.TermsAndConditions:
					return usei18n().translateKey("common_terms_and_conditions");
				case PolicyTermType.PrivacyPolicy:
					return usei18n().translateKey("common_privacy_policy");
				case PolicyTermType.TermsOfUse:
					return usei18n().translateKey("common_website_terms_of_use");
			}
		};
		const footerMenu = computed(() => {
			return [...useCompanyStore().policies, ...useSystemStore().terms, cookiePolicy];
		});
		const footerMenuVoices = computed(() => {
			return footerMenu.value
				.filter((link) => link.IsEnabled)
				.map((link) => {
					const url = {
						name: link.Type === PolicyTermType.PrivacyPolicy ? policyPage : policyTermPage,
						params: {
							...useRoute().params,
							type: kebabCase(link.Type.toString())
						},
						query: useRoute().query
					};
					return {
						label: link.UrlLabel !== "" ? link.UrlLabel : defaultLabelVoices(link.Type),
						url,
						isExternal: false
					};
				});
		});
		onMounted(async() => {
			flags.loading = true;
			await Promise.all([
				useCompanyStore().ensurePolicies(),
				useSystemStore().syncTerms()
			]);
			flags.loading = false;
		});
		return {
			hasMinimumData,
			flags,
			cookiePolicy,
			defaultLabelVoices,
			footerMenu,
			onMounted,
			footerMenuVoices
		};
	}
});

const HANDLER = "_vue_clickaway_handler";

function unbind(el) {
	if (!el) return;
	document.documentElement.removeEventListener("click", el[HANDLER], false);
	delete el[HANDLER];
}

function bind(el, binding) {
	unbind(el);

	const callback = binding.value;
	let initialMacrotaskEnded = false;
	setTimeout(function() {
		initialMacrotaskEnded = true;
	}, 0);

	el[HANDLER] = function(ev) {
		if (initialMacrotaskEnded && !el.contains(ev.target))
			return callback(ev);
	};

	document.documentElement.addEventListener("click", el[HANDLER], false);
}

export default {
	bind,
	unbind,
	update(el, binding) {
		if (binding.value === binding.oldValue) return;
		bind(el, binding);
	}
};

<template>
	<span
		class="spinner-element"
		:size="size"
		:class="{ 'inline': inline }"
	/>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "Spinner",
	props: {
		size: { type: String, required: false, default: "xs" },
		inline: { type: Boolean, required: false, default: false }
	},
	setup() {
		return {};
	}
});
</script>

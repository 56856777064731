import Router from "@app-router/index";
import { ref } from "vue";

export function useRouter() {
	const router = ref(Router);
	return router.value;
}

export const useRoute = () => {
	const route = ref(useRouter().currentRoute);
	return route.value;
};

import { BButton } from "bootstrap-vue";
import { computed, defineComponent } from "vue";

export default defineComponent({
	name: "NavButton",
	components: { BButton },
	props: {
		title: { type: String, required: false, default: "" },
		to: { type: [Object, String], required: false, default: null }
	},
	emits: ["click"],
	setup(props, { emit }) {
		const onBtnClick = (e) => {
			emit("click", e);
		};
		const exact = computed(() => {
			return Boolean(props.to);
		});
		return {
			onBtnClick,
			exact
		};
	}
});

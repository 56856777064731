import { clearFluxData } from "@app-store/reservation-flux";
import useSystemStore from "@app-store/system";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import { NavigationGuardNext, Route } from "vue-router";

import router from ".";
import { maintenanceMode } from "./routes/base";
import { home } from "./routes/reservations";

export function getReservationHome() {
	const SystemStore = useSystemStore();
	return router.resolve({
		name: home,
		params: {
			companyId: SystemStore.CompanyId + "",
			systemId: SystemStore.Id + ""
		}
	});
}
export function redirectToMaintenanceMode(next?: Function) {
	const SystemStore = useSystemStore();
	const currentRoute = router.currentRoute;
	trackUserEvent(EventAI.RedirectToMaintenanceMode, { currentRoute });
	const route = {
		name: maintenanceMode,
		params: {
			companyId: SystemStore.CompanyId + "",
			systemId: SystemStore.Id + ""
		}
	};
	clearFluxData();
	if (next) return next(route);
	else return router.push(route);
}
export function getReservationHomeHref(current: Route) {
	if (current.name === "root") return "#";
	return getReservationHome().href;
}
export function isSkippable(targetRoute: Route, next?: NavigationGuardNext<Vue>) {
	const isSkippable = [undefined, null, ""].indexOf(targetRoute.name) > -1;

	if (isSkippable && next) next();
	return isSkippable;
}
export function isB2CService(route: Route) {
	return route.params && "systemId" in route.params;
}
export function isFullscreenPage(route: Route) {
	return (route.name === "browser-not-supported" || route.name === "disabled-features");
}

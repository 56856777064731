import usei18n from "@app-i18n/index";
import { PolicyType, ReceiveOffersFlags } from "@app-rest/company.interfaces";
import useCompanyStore from "@app-store/company";
import useSystemStore from "@app-store/system";
import { IPrivacyChoice } from "@app-store/user";
import { BFormCheckbox, BFormGroup, BFormRadioGroup, BImg, BLink, BOverlay } from "bootstrap-vue";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";

export default defineComponent({
	name: "PrivacyModal",
	components: {
		BImg,
		BLink,
		BFormGroup,
		BFormCheckbox,
		BFormRadioGroup,
		BOverlay,
		ValidationProvider,
		ValidationObserver
	},
	props: {
		privacyLink: { type: String, required: true }
	},
	emits: ["proceed", "decline"],
	setup(props, { emit }) {
		const centerName = computed(() => {
			return useSystemStore().Name;
		});
		const observer = ref<InstanceType<typeof ValidationObserver> | null>();
		const offers = ref<ReceiveOffersFlags>("KO");
		const innerLoading = ref(false);
		const offersOptions = ref<{text: string, value: ReceiveOffersFlags }[]>([
			{ text: usei18n().translateKey("label_accept"), value: "OK" },
			{ text: usei18n().translateKey("label_do_not_accept"), value: "KO" }
		]);
		const helpers = reactive({
			privacy: null as boolean | null
		});
		onMounted(() => {
			extend("required", required);
		});
		const declineButtonClicked = () => {
			emit("decline");
		};
		const proceedButtonClicked = async() => {
			const isValid = await observer.value?.validate();
			if (isValid) {
				const data: IPrivacyChoice[] = useCompanyStore().privacyData.map((e) => {
					let value: boolean | null = null;
					switch (e.Name) {
						case PolicyType.TermsAndConditions:
							value = helpers.privacy;
							break;
						case PolicyType.ReceiveOffers:
							value = offers.value === "OK";
							break;
					}
					return {
						Accepted: Boolean(value),
						PrivacyName: e.Name,
						PrivacyVersion: e.Version
					};
				});
				emit("proceed", data);
			}
		};
		watch(
			() => innerLoading.value,
			(state: boolean) => {
				innerLoading.value = state;
			}
		);
		return {
			centerName,
			observer,
			offers,
			innerLoading,
			offersOptions,
			helpers,
			onMounted,
			declineButtonClicked,
			proceedButtonClicked
		};
	}
});

import ButtonClose from "@app-components/button-close/button-close.vue";
import CartSummary from "@app-components/cart-summary/cart-summary.vue";
import FieldTooltipFactory from "@app-components/field-tooltip/field-tooltip";
import FieldTooltip from "@app-components/field-tooltip/field-tooltip.vue";
import Price from "@app-components/price/price.vue";
import { useResponsiveUtilities } from "@app-composables/responsive";
import { useRoute } from "@app-composables/router";
import ClickAway from "@app-directives/click-away";
import usei18n from "@app-i18n/index";
import { cartPage, home, reservationBowlersPage, thankyouPage } from "@app-router/routes/reservations";
import useAppStore from "@app-store/app";
import useCartStore, { ICartItem } from "@app-store/cart";
import useReservationStore from "@app-store/reservation";
import useReservationFluxStore from "@app-store/reservation-flux";
import useUserStore, { logout } from "@app-store/user";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import EventManager from "@app-utilities/events-manager";
import { ITimeoutStoppable, timeoutStoppable } from "@app-utilities/func";
import { BBadge, BCol, BDropdownItem, BLink, BNavbarNav, BNavItem, BNavItemDropdown, BRow, BSidebar, BToast, BToaster } from "bootstrap-vue";
import { computed, defineComponent, nextTick, onMounted, onUnmounted, reactive, ref } from "vue";

interface ITooltips {
	cart: boolean;
	account: boolean;
}

export default defineComponent({
	name: "ServiceMenu",
	components: {
		BCol,
		BBadge,
		BDropdownItem,
		BLink,
		BNavbarNav,
		BNavItem,
		BNavItemDropdown,
		BRow,
		BSidebar,
		BToast,
		BToaster,
		ButtonClose,
		CartSummary,
		FieldTooltip,
		Price
	},
	directives: {
		ClickAway
	},
	props: {},
	setup() {
		const showSidebar = ref(false);
		const { translateKey } = usei18n();
		const isLoggedUser = computed(() => {
			return useUserStore().isLoggedIn;
		});
		const isReservationConfirmed = computed(() => {
			return useReservationStore().isReservationConfirmed;
		});
		const firstName = computed(() => {
			return useUserStore().userData.FirstName;
		});
		const userName = computed(() => {
			return translateKey("common_hi_logged_user", {
				userName: useUserStore().userData.FirstName
			});
		});
		const isCartView = computed(() => {
			return useRoute().name === cartPage;
		});
		const isReservationBowlersView = computed(() => {
			return useRoute().name === reservationBowlersPage;
		});
		const isReservationThankyouView = computed(() => {
			return useRoute().name === thankyouPage;
		});
		const cartIsEmpty = computed(() => {
			return useCartStore().isEmpty;
		});
		const isCartVisible = computed(() => {
			const { name } = useRoute();
			return name !== home
					&& name !== thankyouPage
					&& name !== cartPage
					&& name !== reservationBowlersPage;
		});
		const totalItemPrice = computed(() => {
			return (item: ICartItem) => item.UnitPrice * item.Quantity;
		});
		const itemsInCart = computed(() => {
			return useCartStore().getItemsInCart;
		});
		const systemId = computed(() => {
			return useReservationStore().systemId;
		});
		const responsiveUtils = useResponsiveUtilities();
		const toastClasses = computed(() => {
			return {
				...responsiveUtils.responsiveClasses.value,
				"cart-item-added": true
			};
		});
		const cartTooltip = ref<typeof FieldTooltipFactory>();
		const accountTooltip = ref<typeof FieldTooltipFactory>();
		const itemsAdded = ref<ICartItem[]>([]);
		const tooltipFlags = reactive<ITooltips>({
			cart: false,
			account: false
		});
		const showCartItemAddedInfo = ref(false);
		const onCartUnsubscribe = ref(() => {});
		const onWindowResizeUnsubscribe = ref(() => {});
		const helpers = reactive({
			timeoutObject: null as ITimeoutStoppable | null
		});
		const onLoginClick = () => {
			useUserStore().login();
		};
		const itemAddedLabel = computed(() => {
			const itemsAddedQty = itemsAdded.value.reduce(
				(acc, el) => acc + el.Quantity,
				0
			);
			if (itemsAddedQty === 0) return;
			if (itemsAddedQty === 1)
				return translateKey("cart_item_added");
			return translateKey("cart_items_added", {
				qty: itemsAddedQty
			});
		});
		const totalWithoutTaxes = computed(() => {
			return useCartStore().totalWithoutTaxes;
		});
		const checkoutButtonLabel = computed(() => {
			if (!useReservationStore().allMandatoryPagesVisited) return translateKey("label_continue");

			return translateKey("label_checkout");
		});
		const destroy = () => {
			onCartUnsubscribe.value();
			onWindowResizeUnsubscribe.value();
		};
		const cartItemAddedOpen = async() => {
			await nextTick();
			showCartItemAddedInfo.value = true;
			if (helpers.timeoutObject) helpers.timeoutObject.stop();
			helpers.timeoutObject = timeoutStoppable(4000);
			await helpers.timeoutObject.end;
			showCartItemAddedInfo.value = false;
		};
		const tooltipFlagsHide = (tooltipName: keyof ITooltips) => {
			tooltipFlags[tooltipName] = false;
		};
		const tooltipFlagsShow = (tooltipName: keyof ITooltips) => {
			Object.keys(tooltipFlags).map(tooltipFlagsHide as any);
			tooltipFlags[tooltipName] = true;
		};
		const toggleBodyScrollbar = (visible: boolean) => {
			const body = document.getElementsByTagName("body")[0];

			if (visible)
				body.classList.add("overflow-hidden");
			else
				body.classList.remove("overflow-hidden");
		};
		const checkoutButtonClicked = () => {
			useAppStore().setLoadingView(true);
			if (!useReservationStore().allMandatoryPagesVisited) {
				trackUserEvent(EventAI.ContinueFromCartSidebarClicked);
				return useReservationFluxStore().goNextView(useRoute());
			}

			trackUserEvent(EventAI.CheckoutFromCartSidebarClicked);
			return useReservationFluxStore().jumpToView({
				target: {
					...useRoute(),
					name: cartPage
				}
			});
		};
		const showCart = () => {
			if (cartIsEmpty.value) return;
			showCartItemAddedInfo.value = false;
			showSidebar.value = true;
			trackUserEvent(EventAI.CartOpened);
		};
		const hideCart = () => {
			showSidebar.value = false;
			trackUserEvent(EventAI.CartClosed);
		};
		const showAccountTooltip = () => {
			tooltipFlagsShow("account");
		};
		const hideAccountTooltip = () => {
			tooltipFlagsHide("account");
		};
		const onClickOutside = () => {
			hideAccountTooltip();
		};
		const onLogoutClick = () => {
			hideAccountTooltip();
			logout();
		};
		onMounted(() => {
			responsiveUtils.init();
			onCartUnsubscribe.value = EventManager.onCartItemsAdded((items) => {
				if (tooltipFlags.cart) return;
				itemsAdded.value = items;
				cartItemAddedOpen();
			});
			onWindowResizeUnsubscribe.value = EventManager.onWindowResize(() => {
				showCartItemAddedInfo.value = false;
			});
		});
		onUnmounted(() => {
			responsiveUtils.destroy();
		});
		return {
			showSidebar,
			isLoggedUser,
			isReservationConfirmed,
			firstName,
			userName,
			isCartView,
			isReservationBowlersView,
			isReservationThankyouView,
			cartIsEmpty,
			isCartVisible,
			totalItemPrice,
			itemsInCart,
			systemId,
			toastClasses,
			cartTooltip,
			accountTooltip,
			itemsAdded,
			tooltipFlags,
			showCartItemAddedInfo,
			onCartUnsubscribe,
			onWindowResizeUnsubscribe,
			helpers,
			itemAddedLabel,
			totalWithoutTaxes,
			responsiveUtils,
			checkoutButtonLabel,
			onLoginClick,
			toggleBodyScrollbar,
			destroy,
			cartItemAddedOpen,
			tooltipFlagsHide,
			onMounted,
			tooltipFlagsShow,
			showCart,
			hideCart,
			showAccountTooltip,
			hideAccountTooltip,
			onClickOutside,
			onLogoutClick,
			checkoutButtonClicked
		};
	}
});

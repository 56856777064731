import { defineComponent } from "vue";

export default defineComponent({
	name: "MissingFunctionalities",
	components: {
	},
	props: {},
	setup() {
		return {
		};
	}
});

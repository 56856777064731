import usei18n from "@app-i18n/index";
import { getMutationContext, resolveMutationContext } from "@app-store/persistent.plugin";
import useReservationStore from "@app-store/reservation";
import useSystemStore from "@app-store/system";
import useUserStore, { UserModuleInit } from "@app-store/user";

import BowlerApp from "@/main";

import { EventAI, trackUserEvent } from "./app-insights";
import { modalOk, modalOptions, modalWarningConfirm } from "./modals";
import { waitForSystem } from "./system";

let _isNotifyingSelectedOfferNotAvailable: Promise<boolean> | null = null;
export function notifySelectedOfferNotAvailable() {
	if (_isNotifyingSelectedOfferNotAvailable) return _isNotifyingSelectedOfferNotAvailable;

	const content = [
		BowlerApp.$createElement("div", usei18n().translateKey("weboffers_selection_not_available")),
		BowlerApp.$createElement("div", usei18n().translateKey("weboffers_close_to_check_available"))
	];
	_isNotifyingSelectedOfferNotAvailable = modalOk(content, {
		title: usei18n().translateKey("label_information"),
		hideHeaderClose: false
	});
	_isNotifyingSelectedOfferNotAvailable.finally(() => (_isNotifyingSelectedOfferNotAvailable = null));

	return _isNotifyingSelectedOfferNotAvailable;
}
export function notifyReservationExpired() {
	return modalOk([
		BowlerApp.$createElement("div", usei18n().translateKey("weboffers_reservation_expired"))
	], {
		title: usei18n().translateKey("label_information")
	});
}
export function notifyReservationConfirmInProgress() {
	const content = [
		BowlerApp.$createElement("div", usei18n().translateKey("common_error_system_is_processing_your_request"))
	];
	return modalOk(content, {
		title: usei18n().translateKey("label_information"),
		hideHeaderClose: false
	});
}
export function notifyReservationAlreadyConfirmed() {
	const content = [
		BowlerApp.$createElement("div", usei18n().translateKey("reservation_already_confirmed"))
	];
	return modalOk(content, {
		title: usei18n().translateKey("label_information"),
		hideHeaderClose: false
	});
}
let _isAskingForResume: Promise<boolean> | null = null;
export function notifyReservationResumeAvailable() {
	if (_isAskingForResume) return _isAskingForResume;
	trackUserEvent(EventAI.ReservationResumeAsk);
	const content = [
		BowlerApp.$createElement("div", usei18n().translateKey("weboffers_reservation_left")),
		BowlerApp.$createElement("br", ""),
		BowlerApp.$createElement("b", useSystemStore().Name),
		BowlerApp.$createElement("br", ""),
		BowlerApp.$createElement("b", useReservationStore().summary),
		BowlerApp.$createElement("br", ""),
		BowlerApp.$createElement("br", ""),
		BowlerApp.$createElement("div", usei18n().translateKey("weboffers_reservation_new_question"))
	];
	_isAskingForResume = modalOptions<boolean>(content, [
		{ label: usei18n().translateKey("label_start_new"), value: false },
		{ label: usei18n().translateKey("label_continue"), value: true, variant: "primary" }
	], {
		title: usei18n().translateKey("weboffer_reservation_left_title"),
		modalClass: "modal-reservation-resume-available"
	});
	_isAskingForResume
		.then(isResumeAccepted => trackUserEvent(isResumeAccepted ? EventAI.ReservationResumeAccepted : EventAI.ReservationResumeCanceled))
		.finally(() => (_isAskingForResume = null));
	return _isAskingForResume;
}
export function notifyReservationLeftNotAvailable() {
	const content = [
		BowlerApp.$createElement("div", usei18n().translateKey("weboffers_reservation_left")),
		BowlerApp.$createElement("div", usei18n().translateKey("weboffers_reservation_new"))
	];
	return modalOk(content, {
		title: usei18n().translateKey("label_information")
	});
}
export const notifyBackToWebOffersPage = () => modalWarningConfirm(
	[
		BowlerApp.$createElement("div", usei18n().translateKey("common_change_offer_items_currently_in_cart_will_removed"))
	],
	{
		modalClass: "modal-utility modal-changing-offer",
		title: usei18n().translateKey("common_changing_offer"),
		okTitle: usei18n().translateKey("label_empty_cart"),
		cancelTitle: usei18n().translateKey("label_keep_my_offer")
	}
);
export async function persistenceKey(email?: string, resKey?: string) {
	const ReservationStore = useReservationStore();
	const SystemStore = useSystemStore();
	const UserStore = useUserStore();
	if (!ReservationStore.reservationKey && !resKey) return;
	await Promise.all([
		UserModuleInit,
		waitForSystem()
	]);
	if (!SystemStore.info) return;

	const user = email || (UserStore.isLoggedIn ? UserStore.userData.Email : "guest");
	const systemId = SystemStore.Id;
	const reservationKey = resKey ?? ReservationStore.reservationKey;
	return `${user}-${systemId}-${reservationKey}`;
}
export async function clearFluxPersistence() {
	const key = await persistenceKey();
	if (!key) return;
	const cartDbContext = getMutationContext("cart");
	const reservationDbContext = getMutationContext("reservation");
	if (!cartDbContext || !reservationDbContext) return;
	const cartCtx = cartDbContext.access();
	const resCtx = reservationDbContext.access();
	await resolveMutationContext(cartCtx.delete(key));
	await resolveMutationContext(resCtx.delete(key));
}

import useAppStore, { IAppInstance } from "@app-store/app";

export default async function syncSettingsFromBE() {
	const appStore = useAppStore();

	return fetch("/api-app", { method: "GET", cache: "no-cache" })
		.then(async(data) => {
			const config: IAppInstance = await data.json();
			appStore.updateData(config);
			return config;
		})
		.catch((error) => {
			console.warn("[WARN] /api-app not working");
			console.error(error);
			return null;
		});
}

import { timeout } from "@app-utilities/func";

import PortalRestClientFactory from ".";

export interface ICompanyTheme {
	ThemeUrl: string
}

export interface IApplyTheme {
	companyId: number,
	systemId: number,
	previewUrl?: string
}

export default class ThemeProvider extends PortalRestClientFactory {
	private _actual: ICompanyTheme | null = null;

	private async getActualTheme(companyId: number, systemId: number) {
		return this.get<ICompanyTheme>(`/companies/${companyId}/themes/${systemId}/website/current`);
	}

	private async applyToPage(resource: string) {
		const request = await fetch(resource, {
			cache: "force-cache",
			credentials: "omit",
			mode: "cors",
			method: "GET",
			referrer: location.href,
			referrerPolicy: "no-referrer-when-downgrade",
			headers: new Headers({ "Access-Control-Allow-Origin": "*" })
		});
		const data = await request.text();
		const tag = document.createElement("style");
		tag.innerText = data;
		document.head.appendChild(tag);
		await timeout(1000);
	}

	private async applyActual(companyId: number, systemId: number) {
		const themeResource = await this.getActualTheme(companyId, systemId);
		if (this._actual?.ThemeUrl === themeResource.data?.ThemeUrl)
			return themeResource.data?.ThemeUrl;
		this._actual = themeResource.data;
		if (themeResource.data?.ThemeUrl)
			await this.applyToPage(themeResource.data?.ThemeUrl);
		return themeResource.data?.ThemeUrl;
	}

	private async applyThemeUrl(url: string) {
		await this.applyToPage(url);
		return url;
	}

	async apply(options: IApplyTheme) {
		const port = parseInt(location.host.split(":")[1] ?? 0);
		const themesDisabled = port >= 3000 && port < 4000;
		let themeEndpoint;
		if (!themesDisabled) {
			if (options.previewUrl)
				themeEndpoint = await this.applyThemeUrl(options.previewUrl);
			else themeEndpoint = await this.applyActual(options.companyId, options.systemId);
		}
		const url = themeEndpoint ? new URL(themeEndpoint) : null;
		const parts = url?.pathname.substr(1).split("/") ?? [];
		return {
			url,
			companyId: parts.length && parts[0] !== "main" ? parts[0] : null,
			codename: parts.length ? parts[2] : "develop",
			revision: parts.length ? parts[3] : ""
		};
	}
}

import { useRouter } from "@app-composables/router";
import useReservationStore from "@app-store/reservation";
import useSystemStore from "@app-store/system";
import useUserModule from "@app-store/user";
import EventManager from "@app-utilities/events-manager";
import { ApplicationInsights, IConfig, IConfiguration, ITraceTelemetry } from "@microsoft/applicationinsights-web";
import { RestError } from "scarlett";

import { IErrorEventHandler } from "./events.definitions";

type TrackedException = {
	exception: Error | DOMException;
	properties: Record<string, any>;
}

export enum EventAI {
	FatalError = "fatal-error",
	BrowserTabLeave = "browser-tab-leave",
	BrowserTabResume = "browser-tab-resume",
	AppReady = "app-ready",
	RedirectToNotFound = "redirected-to-not-found",
	RedirectToUnsupportedBrowser = "redirected-to-unsupported-browser",
	RedirectToDisabledFeatures = "redirected-to-disabled-features",
	RedirectToMaintenanceMode= "redirect-to-maintenance-mode",
	RedirectToHome = "redirect-to-home",
	RedirectToChannelMismatch = "redirect-to-channel-mismatch",
	FlowStarted = "flow-started",
	FlowNextStep = "flow-next-step",
	FlowPreviousStep = "flow-previous-step",
	FlowResume = "flow-resume",
	FlowEndByUser = "flow-end-by-user",
	FlowEndSuccessful = "flow-end-successful",
	FlowEndUnsuccessful = "flow-end-unsuccessful",
	SaveBowlersOptions = "save-bowlers-options",
	CartOpened = "cart-opened",
	CartClosed = "cart-closed",
	ContinueFromCartSidebarClicked = "continue-from-cart-sidebar-clicked",
	CheckoutFromCartSidebarClicked = "checkout-from-cart-sidebar-clicked",
	CartReduceItemQuantity = "cart-reduce-item-quantity",
	CartIncreaseItemQuantity = "cart-increase-item-quantity",
	CartRemoveItem = "cart-remove-item",
	CartBackToStep = "cart-back-to-step",
	ReservationCreated = "reservation-created",
	ReservationResumeAsk = "reservation-resume-ask",
	ReservationResumeAccepted = "reservation-resume-accepted",
	ReservationResumeCanceled = "reservation-resume-canceled",
	ReservationTimeoutAfterConfirmPayment = "reservation-timeout-after-confirm-payment",
	ReservationTimeoutAfterClosePaymentPopup = "reservation-timeout-after-close-payment-popup",
	ReservationTokenMissingOnCreate = "reservation-token-is-missing-create",
	ReservationTokenMissingOnRenew = "reservation-token-is-missing-renew",
	UserClosedPaymentPopup = "user-closed-payment-popup",
	UserClosedPaymentModal = "user-closed-payment-modal",
	UserLeftPaymentModal = "user-left-payment-modal",
	PopupBlocked = "popup-blocked",
	UserLoginSuccessful = "user-login-successful",
	UserLoginError = "user-login-error",
	CartConfirmed = "cart-confirmed",
	PaymentStart = "payment-start",
	PaymentError = "payment-error",
	PaymentSuccessful = "payment-successful",
	IndexedDBFailure = "indexedDb-failure"
}

export let AppInsights: ApplicationInsights | undefined;

export function setupAppInsights(config: IConfiguration & IConfig) {
	if (AppInsights) return;
	AppInsights = new ApplicationInsights({ config });
	AppInsights.loadAppInsights();
}

export function trackView(name: string, action: "start" | "end", path: string) {
	if (!AppInsights) return;

	switch (action) {
		case "start": AppInsights.startTrackPage(name); break;
		case "end": AppInsights.stopTrackPage(name, path); break;
	}
}

export function trackTrace(trace: ITraceTelemetry, extraProps?: Record<string, any>, flush = false) {
	if (!AppInsights) return;
	const properties = Object.assign(
		{},
		extraProps ?? {}
	);
	AppInsights.trackTrace({ ...trace, properties });
	if (flush) AppInsights.flush();
}

export async function trackException(error: Parameters<IErrorEventHandler>[0], extras?: Parameters<IErrorEventHandler>[1]): Promise<TrackedException | undefined> {
	if (!AppInsights) return;

	const useUserModule = (await import("@app-store/user")).default;
	const tracked: TrackedException = { exception: error, properties: (extras ?? {}) };

	if (!extras)
		extras = {};

	if (tracked.exception instanceof RestError) {
		let rawBody = "";
		try {
			rawBody = await tracked.exception.fetchResponse?.text() ?? "";
		} catch (e) {}

		extras.instanceOf = "RestError";
		const api = {
			url: tracked.exception.request?.url.toString(),
			method: tracked.exception.request?.method,
			params: tracked.exception.request?.options.query,
			payload: tracked.exception.request?.body,
			headers_request: tracked.exception.request?.options.headers,
			headers_response: tracked.exception.fetchResponse?.headers,
			status_code: tracked.exception.statusCode,
			error_code: tracked.exception.code,
			response: tracked.exception.data,
			rawBody
		};
		tracked.properties = { api, extras, instanceOf: "RestError", user: useUserModule().userData };
		const isTimeout = (tracked.exception.statusCode + "").toLowerCase() === "timeout";
		if (tracked.exception.statusCode && tracked.exception.request?.method && (isTimeout || !isNaN(+tracked.exception.statusCode)))
			tracked.exception.message = `[${tracked.exception.request.method.toUpperCase()}-${api.status_code}] ${tracked.exception.request?.url.pathname}`;
	} else if (error) {
		tracked.properties.code = error.name;
		const exceptionTypes = [
			DOMException,
			EvalError,
			RangeError,
			ReferenceError,
			SyntaxError,
			TypeError,
			URIError,
			AggregateError
		];
		for (let i = 0; i < exceptionTypes.length; i++) {
			if (error instanceof exceptionTypes[i]) {
				tracked.properties.instanceOf = exceptionTypes[i].name;
				break;
			}
		}
	}
	AppInsights.trackException(tracked);
	AppInsights.flush();
	return tracked;
}

export async function trackUserEvent(name: EventAI, extraProps?: Record<string, any>, flush = false) {
	if (!AppInsights) return;
	const userModule = useUserModule();
	const systemModule = useSystemStore();
	const router = useRouter();
	const reservationModule = useReservationStore();

	const [companyId, systemId] = location.pathname.split("/").filter(Boolean);
	const properties = Object.assign(
		{},
		extraProps ?? {},
		{
			user: userModule.userData,
			user_account: userModule.AccountInfo,
			user_customer: userModule.CustomerInfo
		},
		{
			routePath: router.currentRoute.fullPath,
			routeName: router.currentRoute.name
		},
		{ companyId, systemId },
		{
			...(reservationModule.reservationKey && { reservationKey: reservationModule.reservationKey }),
			...(reservationModule.reservationToken && { reservationToken: reservationModule.reservationToken })
		},
		{
			...(systemModule.functionalities && { functionalities: systemModule.functionalities }),
			...(systemModule.configurations && { configurations: systemModule.configurations })
		}
	);
	AppInsights.trackEvent({ name, properties });
	if (flush) AppInsights.flush();
}

EventManager.onUserChanged(async user => {
	// @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md#account-identifiers
	if (!AppInsights)
		return;

	if (user && user.AccountInfo)
		AppInsights.setAuthenticatedUserContext(user.AccountInfo.username, user.AccountInfo.localAccountId, true);
	else AppInsights.clearAuthenticatedUserContext();
});

import PortalRestClientFactory from "@app-rest/index";
import ThemeProvider, { IApplyTheme } from "@app-rest/theme-provider";
import Bowser from "bowser";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

const before2019 = {
	ie: "<12",
	chrome: "<64",
	edge: "<80",
	firefox: "<58",
	opera: "<51",
	safari: "<12",
	mobile: {
		chrome: "<64",
		safari: "<12",
		samsung_internet: "<6.4"
	}
};

interface IAppAzurePolicies {
	policySignUpSignIn: string
	policyResetPassword: string
	policyEditProfile: string
}
interface IAppAzureConfig extends IAppAzurePolicies {
	instance: string
	clientId: string
	domain: string
	registrationApp: string
}
export type AzureADB2CFlow = keyof IAppAzurePolicies

type AppLaunchMode = "" | "DevelopSession" | "DevelopAndTestingSession" | "TestingSession";
export interface IAppInstance {
	version: string
	env: string
	channel: string
	website: string
	websiteBeta: string
	themeLoaded: boolean
	launchMode: AppLaunchMode;
	azure: IAppAzureConfig
	connectionString: string
}
interface PWABaseRoute {
	companyId: string;
	systemId: string;
}
type BrowserFeature = "Cookies" | "LocalStorage" | "IndexedDB"
type BrowserFeaturesCheck = Map<BrowserFeature, boolean>

let _restClient: PortalRestClientFactory | null;
export function ensureRestClient() {
	if (_restClient) return _restClient;
	const restClient = _restClient = new PortalRestClientFactory();
	return restClient;
}

@Module class AppStoreFactory extends VuexModule implements IAppInstance {
	themeProvider: ThemeProvider | null = null;
	version = "";
	env: "Development" | "Staging" | "Expo" | "Production" = "Production";
	channel: "Beta" | "Stable" = "Stable";
	website = "";
	websiteBeta = "";
	themeName = "no-theme";
	themeLoaded = false;
	loadingView = true;
	launchMode: AppLaunchMode = "";
	azure: IAppAzureConfig = null as any;
	connectionString = "";
	requiredBrowserFeatures: BrowserFeaturesCheck | null = null;
	browser = Bowser.getParser(window.navigator.userAgent);
	azurePopupWindowIsOpen = false;
	defaultPWABaseRoute: PWABaseRoute | null = null;
	get isDevelopSession() {
		return this.launchMode === "DevelopSession" || this.launchMode === "DevelopAndTestingSession";
	}

	get isTestingSession() {
		return this.launchMode === "TestingSession" || this.launchMode === "DevelopAndTestingSession";
	}

	get isPWASession() {
		return matchMedia("(display-mode: standalone)").matches;
	}

	get isValidBrowser() {
		const browser = this.browser;
		if (!browser) return false;

		if (browser.getOSName(true) === "ios") return true;

		if (browser.satisfies(before2019)) return false;
		return true;
	}

	get hasAllRequiredBrowserFeatures() {
		if (!this.requiredBrowserFeatures) return;
		let entries = 0;
		let passed = 0;
		for (const [key, value] of this.requiredBrowserFeatures.entries()) {
			entries++;
			if (value) passed++;
		}
		return entries === passed;
	}

	get hasRequiredBrowserFeature() {
		return (feature: BrowserFeature) => Boolean(this.requiredBrowserFeatures?.get(feature));
	}

	@Mutation ensureThemeProvider() {
		if (!this.themeProvider)
			this.themeProvider = new ThemeProvider();
	}

	@Mutation updateData(appData: IAppInstance) {
		Object.keys(appData).forEach(prop => {
			this[prop] = appData[prop];
		});
	}

	@Mutation setAzurePopupWindowIsOpen(state: boolean) {
		this.azurePopupWindowIsOpen = state;
	}

	@Mutation setThemeLoading() {
		this.themeLoaded = false;
	}

	@Mutation setThemeName(themeName?: string | null | false) {
		this.themeName = themeName || "no-theme";
	}

	@Mutation setThemeRequireLoad() {
		this.themeLoaded = false;
	}

	@Mutation setThemeLoaded() {
		this.themeLoaded = true;
	}

	@Mutation setLoadingView(state: boolean) {
		this.loadingView = state;
	}

	@Mutation setRequiredBrowserFeatures(map: BrowserFeaturesCheck) {
		this.requiredBrowserFeatures = map;
	}

	@Mutation setDefaultPWABaseRoute(route: PWABaseRoute) {
		this.defaultPWABaseRoute = route;
	}

	@Action ensurePWAContext(route?: PWABaseRoute) {
		try {
			const storageCompanyId = localStorage.getItem("companyId");
			const storageSystemId = localStorage.getItem("systemId");
			if (this.isPWASession) {
				if (storageCompanyId && storageSystemId) {
					this.setDefaultPWABaseRoute({
						companyId: storageCompanyId,
						systemId: storageSystemId
					});
				}
			} else if (!this.isPWASession && route && !storageCompanyId && !storageSystemId) {
				localStorage.setItem("companyId", route.companyId);
				localStorage.setItem("systemId", route.systemId);
				this.setDefaultPWABaseRoute(route);
			}
		} catch (e) {}
	}

	@Action async syncTheme(options: IApplyTheme) {
		this.setThemeLoading();
		this.ensureThemeProvider();
		if (!this.themeProvider) return;
		const theme = await this.themeProvider.apply(options);
		this.setThemeName(theme.codename);
		this.setThemeLoaded();
	}

	@Action async ensureRequiredBrowserFeatures() {
		if (this.requiredBrowserFeatures) return;

		function cookiesCheck() {
			return new Promise<boolean>((resolve) => {
				if (!window.navigator.cookieEnabled)
					resolve(false);
				else {
					try {
						document.cookie = "qamfcheck=1; SameSite=None; Secure";
						const ret = document.cookie.indexOf("qamfcheck=") !== -1;
						if (!ret) resolve(false);

						document.cookie = "qamfcheck=1; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure";
						resolve(true);
					} catch (e) {
						resolve(false);
					}
				}
			});
		}
		function localStorageCheck() {
			return new Promise<boolean>((resolve) => {
				if (!("localStorage" in window))
					resolve(false);
				else {
					const key = "qamf";
					const value = "check";
					try {
						localStorage.setItem(key, value);
						localStorage.removeItem(key);
						resolve(true);
					} catch (e) {
						resolve(false);
					}
				}
			});
		}
		function indexedDBCheck() {
			return new Promise<boolean>((resolve) => {
				if (!window.indexedDB) resolve(false);
				else {
					try {
						const request = indexedDB.open("user");
						request.onsuccess = () => {
							indexedDB.deleteDatabase("user");
							resolve(true);
						};
						request.onerror = function() { resolve(false) };
					} catch (e) {
						resolve(false);
					}
				}
			});
		}
		const featuresMap: BrowserFeaturesCheck = new Map();

		featuresMap.set("Cookies", await cookiesCheck());
		featuresMap.set("LocalStorage", await localStorageCheck());
		featuresMap.set("IndexedDB", await indexedDBCheck());

		this.setRequiredBrowserFeatures(featuresMap);
	}
}

const moduleName = "app";

let AppStore: AppStoreFactory | null;

function useAppStore() {
	if (AppStore) return AppStore;

	const mod = AppStore = new AppStoreFactory({ store, name: moduleName });

	return mod;
}
export default useAppStore;

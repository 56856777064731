<template>
	<div
		class="layout"
		:slots="slots"
		:variant="variant"
		:fluid="fluid"
	>
		<div
			v-if="Number(slots) === 1"
			class="slot"
		>
			<slot />
		</div>
		<template v-else>
			<div
				v-for="(col, index) in Number(slots)"
				:key="index"
				:slot="(index + 1).toString()"
				class="slot"
			>
				<slot :name="(index + 1)" />
			</div>
		</template>
	</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "Layout",
	props: {
		slots: { type: [String, Number], default: 1 },
		variant: { type: String, default: null },
		fluid: { type: Boolean, default: false }
	},
	setup() {
		return {};
	}
});
</script>

import usei18n from "@app-i18n/index";
import { BvMsgBoxOptions, ModalPlugin } from "bootstrap-vue";
import Vue, { VNode } from "vue";

import BowlerApp from "@/main";

Vue.use(ModalPlugin);

interface IModalOption {
	label: string
	value: any
	variant?: string
}

function getDefaultOptions(): BvMsgBoxOptions {
	return {
		okTitle: usei18n().translateKey("label_ok"),
		cancelTitle: usei18n().translateKey("label_cancel"),
		cancelVariant: "outline-primary",
		okVariant: "primary",
		noCloseOnBackdrop: true,
		noCloseOnEsc: true,
		centered: true,
		scrollable: true,
		modalClass: "modal-utility",
		headerClass: "",
		bodyClass: "",
		footerClass: ""
	};
}
export function modalEmpty(message: string | VNode[], overrides?: BvMsgBoxOptions): Promise<boolean | null> {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	const id = "modal-empty" + Date.now();
	localOptions.id = localOptions.id ?? id;
	localOptions.modalClass = localOptions.modalClass + " modal-empty";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;

	const messageNodes = typeof message === "string"
		? [BowlerApp.$createElement("div", message)]
		: message;
	const messageWrapper = BowlerApp.$createElement("div", { class: "message-wrapper" }, messageNodes);
	return BowlerApp.$bvModal.msgBoxConfirm([messageWrapper], localOptions);
}

export function modalError(message: string | VNode[], overrides?: BvMsgBoxOptions): Promise<boolean | null> {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-error";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	return BowlerApp.$bvModal.msgBoxConfirm(message, localOptions);
}
export function modalWarning(message: string | VNode[], overrides?: BvMsgBoxOptions): Promise<boolean | null> {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-warning";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	return BowlerApp.$bvModal.msgBoxConfirm(message, localOptions);
}
export async function modalFatal(overrides?: BvMsgBoxOptions) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-error";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	localOptions.title = usei18n().translateKey("common_error_modal_something_went_wrong");
	localOptions.centered = true;
	localOptions.noCloseOnEsc = true;
	localOptions.noCloseOnBackdrop = true;
	const message = [
		BowlerApp.$createElement("div",
			[
				BowlerApp.$createElement("p", usei18n().translateKey("common_error_modal_page_does_not_work")),
				BowlerApp.$createElement("p", usei18n().translateKey("common_error_modal_message_please_close_this_window_to_refresh"))
			]
		)
	];
	await BowlerApp.$bvModal.msgBoxConfirm(message, localOptions);
}
export async function modalCartError(overrides?: BvMsgBoxOptions) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-error";
	localOptions.hideHeaderClose = true;
	localOptions.title = usei18n().translateKey("common_error_modal_something_went_wrong");
	localOptions.centered = true;
	localOptions.noCloseOnEsc = true;
	localOptions.noCloseOnBackdrop = true;
	const message = [
		BowlerApp.$createElement("div",
			[
				BowlerApp.$createElement("p", usei18n().translateKey("common_error_modal_page_does_not_work")),
				BowlerApp.$createElement("p", usei18n().translateKey("common_error_modal_message_please_close_this_window_to_start_a_new_reservation"))
			]
		)
	];
	await BowlerApp.$bvModal.msgBoxOk(message, localOptions);
}
export function modalConfirm(message: string | VNode[], overrides?: BvMsgBoxOptions): Promise<boolean | null> {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-confirm";
	return BowlerApp.$bvModal.msgBoxConfirm(message, localOptions);
}
export function modalWarningConfirm(message: string | VNode[], overrides?: BvMsgBoxOptions): Promise<boolean | null> {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-warning modal-confirm";
	return BowlerApp.$bvModal.msgBoxConfirm(message, localOptions);
}
export function modalWarningOneButton(message: string | VNode[], overrides?: BvMsgBoxOptions): Promise<boolean | null> {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-warning modal-confirm modal-one-button";
	localOptions.footerClass = localOptions.modalClass + " border-top-0";
	return BowlerApp.$bvModal.msgBoxOk(message, localOptions);
}
export function modalOk(message: string | VNode[], overrides?: BvMsgBoxOptions): Promise<boolean> {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-ok";
	return BowlerApp.$bvModal.msgBoxOk(message, localOptions);
}
export function modalOptions<T = any>(message: string | VNode[], options: IModalOption[], overrides?: BvMsgBoxOptions): Promise<T> {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	const id = "modal-with-options-" + Date.now();
	localOptions.id = id;
	localOptions.modalClass = localOptions.modalClass + " modal-utility modal-with-options";
	localOptions.footerClass = "d-none";

	return new Promise(resolve => {
		const messageNodes = typeof message === "string"
			? [BowlerApp.$createElement("div", message)]
			: message;
		const messageWrapper = BowlerApp.$createElement("div", { class: "message-wrapper" }, messageNodes);
		const optionsWrapper = BowlerApp.$createElement(
			"template",
			{ class: "options-wrapper modal-footer", attrs: { options: options.length }, slot: "footer" },
			options.map(option => {
				const btn = BowlerApp.$createElement("b-button", {
					props: {
						variant: option.variant ?? "outline-primary"
					},
					on: {
						click() {
							resolve(option.value);
							BowlerApp.$bvModal.hide(id);
						}
					}
				}, [option.label]);
				return btn;
			})
		);
		BowlerApp.$bvModal.msgBoxConfirm([messageWrapper, optionsWrapper], localOptions);
	});
}

export const modalPopupBlock = () => modalWarning([
	BowlerApp.$createElement("div", { class: "py-4" }, usei18n().translateKey("common_error_popup_blocked_text_generic"))
], {
	title: usei18n().translateKey("common_error_popup_blocked_title")
});

import type { MandatoryPage } from "@app-utilities/const";

import type { ICartItemDetails } from "./cart.interfaces";
import type { IPlayersQuantity } from "./systems.interfaces";

export type ReservationViewName =
	"reservation-offers" | "reservation-footwears" | "reservation-extras" | "reservation-food-beverage"
	| "reservation-cart" | "reservation-bowlers" | "reservation-thankyou" | "reservation-home";
export interface IOffersQuery {
	systemId: number;
	offerId: number;
}
export interface IOffersQuerySystem {
	systemId: number;
}
export interface IOfferQueryPagination {
	page: number;
	itemsPerPage: number;
}
export interface IOfferQueryDate {
	datetime: Date | string;
}
export interface IOfferQueryPlayers {
	players: IPlayersQuantity[] | string;
}
export interface IOfferQueryCategoryId {
	categoryId: number;
}
enum FoodBeverageType {
	Items = "Items",
	Packages = "Packages"
}
export interface IFoodBeverage {
	Id: number;
	CategoryId: number;
	Name: string;
	Tags: string[];
	ImageUrl: string;
	Description: string;
	Price: number;
	Type: FoodBeverageType;
}
export interface IFoodBeverageCategory {
	CategoryId: number;
	CategoryName: string;
}
interface IModifierBE {
	IdOriginal: number,
	Name: string,
	Price?: number
}
export interface IModifierGroupBE {
	IdModifierGroup: number,
	Name: string,
	Rules?: {
		MinQuantity?: number,
		MaxQuantity?: number
	},
	Modifiers: IModifierBE[]
}
interface IPackageItemsBE {
	Name: string,
	IdOriginal: number,
	ModifiersGroups: IModifierGroupBE[]
}
export interface IItemModifierGroupBE {
	Name: string,
	ModifiersGroups: IModifierGroupBE[]
}
export interface IPackageModifierGroupBE {
	Name: string,
	PackageItems: IPackageItemsBE[]
}
export interface IOffer {
	OfferId: number;
	Name: string;
	Description: string;
	ImageUrl: string;
	Items: IOfferItem[];
}
export interface IOfferItem {
	ItemId: number;
	Quantity: number;
	QuantityType: "Minutes" | "Games" | "Unlimited";
	Lanes: number;
	Total: number;
	Remaining: number;
	Alternatives: IOfferItemAlternative[];
}
export interface IOfferItemAlternative {
	DateTime: string;
	Total: number;
	Remaining: number;
}
export interface IOfferSelected
		extends Pick<IOffer, "OfferId" | "Name" | "Description">,
			Pick<IOfferItem, "ItemId"> {
	Datetime: Date;
	Total: number;
}
export interface IReservationExtra {
	Id: number;
	Name: string;
	ImageUrl: string;
	Description: string;
	Price: number;
}
export interface IFootwear {
	Name: string;
	Description?: string;
	Price: number;
	PlayerTypeId?: number;
	PriceKeyId: number;
}
export interface IFootwearResponse {
	BookingDate: string;
	OfferId: number;
	Shoes: IFootwear[];
	Socks: IFootwear[];
}
export interface IReservationApprovePayment {
	Url: string,
	MobileUrl: string,
	Method: "POST" | "PUT" | "PATCH" | "GET",
	Data?: object
}
export interface IReservationLifetime {
	ReservationKey: string
	LifetimeMinutes: number
	ApprovePayment?: IReservationApprovePayment
}
export interface IReservationConfirmResponse {
	NeedPayment: boolean
	ApprovePayment?: IReservationApprovePayment,
	OperationId: string
}
export interface IReservationRequest {
	DateFrom: Date,
	WebOfferId: number;
	WebOfferTariffId: number,
	PlayersList: {
		TypeId: number,
		Number: number
	}[]
}
export interface IReservationSummary {
	TotalWithoutTaxes: number;
	TotalItems: number;
	AutoGratuity: number;
	Deposit: number;
	Fee: number;
	AddedTaxes: number;
	Total: number;
}
export interface IReservationConfirmData {
	ReturnUrl: string;
	Summary: IReservationSummary;
	Items: ICartItemDetails[];
}
export interface IReservationGuestConfirmData {
	GuestDetails: {
		ReferentName: string,
		PhoneNumber: string,
		Email: string
	},
	Cart: IReservationConfirmData
}
export interface IReservationPayment {
	Provider: string;
	TransactionId: string;
	ReferenceId: string;
}
export interface IReservationBowlerOptions {
	WantBumpers: boolean;
	Name?: string | null;
	ShoeSize?: string | null;
}
export interface IReservationBowlerOptionsWithSize extends IReservationBowlerOptions{
	Size: IShoesSize | null
}
export type AvailabilityCheckInterval = 5 | 15 | 60
export interface IReservationOptions {
	AvailabilityCheckInterval: AvailabilityCheckInterval
	BufferTime: number
	MaxLanesNumberForReservation: number
	MaxPlayersNumberForLane: number
}
export interface IWebOfferOptions {
	EarliestAvailability: string
	BowlerPlayersPerLaneMin: number
	BowlerPlayersPerLaneMax: number
	ReservationLanesMin: number
	ReservationLanesMax: number
	CanSetShoes: boolean
	CanSetBumpers: boolean
	IsShoesEnabled: boolean
	ShowGamesAndExtraPage: boolean
	ShowFoodAndBeveragePage: boolean
}
export interface IShoesSize {
	Name: string
	Id: number
	CategoryId: number
}
export interface IShoesCategory {
	DisplayName: string
	Active: boolean
	Id: number
	ShoesSize: IShoesSize[]
}
export interface IShoesCategoryData {
	CategoriesShoesSizes: IShoesCategory[]
}
export type PaymentStatus = "INIT" | "ONGOING" | "COMPLETED" | "CANCELED" | "DECLINED" | "TECH_ERROR"
export type ReservationStatus = "NOT_FOUND" | "TEMPORARY" | "CONFIRMED" | "EXPIRED"
export interface IMandatoryPage {
	name: MandatoryPage
	visited: boolean
}
export interface IReservationData {
	systemId: number
	offer: IOfferSelected | null
	pickedDatetime: Date | null
	bowlers: IPlayersQuantity[]
	bowlersTotal: number
	shoesTotal: number
	reservationKey: string | null
	reservationToken: string | null
	reservationKeyExpire: Date | null
	reservationStatus: ReservationStatus | null
	reservationNeedPayment: boolean | null
	paymentStatus: PaymentStatus | null
	latestView: ReservationViewName | null
	mandatoryPages: IMandatoryPage[] | null
}

type ReservationCommonError = "ReservationAlreadyConfirmed" | "ReservationConflict" | "ReservationNotFound" | "ReservationNotTemporary"

export type ReservationOffersError = "QDateTimeNotValid"
export const ReservationStatusErrors = ["TransactionNotFound"] as const;
export type ReservationStatusErrorCodes = typeof ReservationStatusErrors[number];
export type ReservationDeleteError = "PaymentInitiated" | "ReservationConflict"
export type ReservationLifetimeError = ReservationCommonError | "BookingConflict" | "ConquerorOffline" | "QResourceNotAvailable" | "UnlimitedConfigConflict"
export type ReservationTimeoutError = ReservationCommonError | "ReservationTimedOut"
export type ReservationConfirmError = ReservationCommonError | "ConfirmInProgress" | "ModifierChanged" | "ModifierNotFound" | "ModifierNotFoundOrExpired" | "PriceAvailabilityChanged" | "PriceValueChanged" | "PaymentException" | "PaymentProviderNotConfigured" | "ReservationExpired"
export type ReservationError = ReservationCommonError | "ModifierChanged" | "ModifierNotFound" | "OperationIdNotFound" | "PaymentCanceled" | "PaymentProviderNotConfigured" | "ReservationExpired"
export interface IReservationStatusResponse {
	OperationId: string
	PaymentStatus: PaymentStatus
	ReservationId: string
	ReservationStatus: ReservationStatus
}

import { BButton } from "bootstrap-vue";
import { computed, defineComponent } from "vue";

export default defineComponent({
	name: "Quantifier",
	components: {
		BButton
	},
	props: {
		value: { type: Number, default: null },
		id: { type: Number, default: 0 },
		min: { type: Number, default: 0 },
		max: { type: Number, default: Infinity },
		subDisabled: { type: Boolean },
		addDisabled: { type: Boolean }
	},
	emits: ["input", "click-sub", "click-add"],
	setup(props, { emit }) {
		const submitModel = (n: number) => {
			emit("input", n);
		};
		const isSubDisabled = computed(() => {
			return typeof props.subDisabled !== "undefined"
				? props.subDisabled
				: props.value === 0;
		});
		const isAddDisabled = computed(() => {
			return typeof props.addDisabled !== "undefined"
				? props.addDisabled
				: props.value === props.max;
		});
		const onSubClick = () => {
			if (typeof props.value === "undefined") return emit("click-sub");
			const next = props.value - 1;
			emit("click-sub", props.value, next);
			if (next < props.min) return;
			submitModel(next);
		};
		const onAddClick = () => {
			if (typeof props.value === "undefined") return emit("click-add");
			const next = props.value + 1;
			emit("click-add", props.value, next);
			if (next > props.max) return;
			submitModel(next);
		};
		return {
			isSubDisabled,
			isAddDisabled,
			submitModel,
			onSubClick,
			onAddClick
		};
	}
});

import EventsManager from "@app-utilities/events-manager";
import GetCssVariable from "@app-utilities/get-css-variable";
import { computed, ref } from "vue";
function useBreakpoints() {
	return {
		xs: GetCssVariable("--breakpoint-xs"),
		sm: GetCssVariable("--breakpoint-sm"),
		md: GetCssVariable("--breakpoint-md"),
		lg: GetCssVariable("--breakpoint-lg"),
		xl: GetCssVariable("--breakpoint-xl")
	};
}

export function useResponsiveUtilities() {
	const winWidth = ref(0);
	const unsubscribe = ref(() => {});

	const isExtraSmall = computed(() => {
		return winWidth.value < useBreakpoints().sm;
	});

	const isSmall = computed(() => {
		return winWidth.value < useBreakpoints().md;
	});

	const isMedium = computed(() => {
		return winWidth.value >= useBreakpoints().md && winWidth.value < useBreakpoints().lg;
	});

	const isBig = computed(() => {
		return winWidth.value >= useBreakpoints().lg;
	});

	const isExtraBig = computed(() => {
		return winWidth.value >= useBreakpoints().xl;
	});

	const responsiveClasses = computed(() => {
		return {
			"is-small": isSmall.value,
			"is-medium": isMedium.value,
			"is-big": isBig.value
		};
	});

	const onResize = () => {
		winWidth.value = window.innerWidth;
	};

	const destroy = () => {
		unsubscribe.value();
	};

	const init = () => {
		unsubscribe.value = EventsManager.onWindowResize(onResize);
		onResize();
	};

	return {
		isExtraSmall,
		isSmall,
		isMedium,
		isBig,
		isExtraBig,
		responsiveClasses,
		destroy,
		init
	};
}

import { BvToastOptions, ToastPlugin } from "bootstrap-vue";
import Vue, { VNode } from "vue";

import BowlerApp from "@/main";

Vue.use(ToastPlugin);

function getDefaultOptions(): BvToastOptions {
	return {
		appendToast: true,
		autoHideDelay: 4000,
		noCloseButton: true,
		solid: true
	};
}
export function toastSuccess(message: string | VNode[], overrides?: BvToastOptions) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.variant = "success";
	localOptions.isStatus = true;
	localOptions.toastClass = "toast-signup";
	return BowlerApp.$bvToast.toast(message, localOptions);
}

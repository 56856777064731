import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import useLangStore from "@app-store/lang";
import useSystemStore from "@app-store/system";
import { EventAI, trackTrace, trackUserEvent, trackView } from "@app-utilities/app-insights";
import { isBookingPage, isErrorPage } from "@app-utilities/const";
import type { NetworkInformation } from "@app-utilities/interfaces";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import type { RawLocation, Route } from "vue-router";
import type VueRouter from "vue-router";

import { notFoundFallbackViewName } from ".";
import { isSkippable, redirectToMaintenanceMode } from "./helpers";
import { maintenanceMode } from "./routes/base";
import { home } from "./routes/reservations";

export default (router: VueRouter) =>
	router.beforeEach(async(to, from, nextRouter) => {
		const AppStore = useAppStore();
		const CompanyStore = useCompanyStore();
		const LangStore = useLangStore();
		const SystemStore = useSystemStore();

		if (AppStore.azurePopupWindowIsOpen)
			return Promise.resolve();

		async function next(target?: false | void | RawLocation | ((vm: Vue) => any) | undefined) {
			const name = typeof target === "object"
				? target.name
				: to.name;

			if (name && !AppStore.isDevelopSession)
				trackView(name, "start", to.fullPath);

			nextRouter(target);
		}
		async function redirectToNotFound(reason: string, from: Route) {
			await LangStore.ensureLanguage();
			trackUserEvent(EventAI.RedirectToNotFound, { reason, from });
			return nextRouter({
				name: "not-found",
				params: to.params,
				query: to.query
			});
		}

		if (to.name === notFoundFallbackViewName)
			trackUserEvent(EventAI.RedirectToNotFound, { reason: "vue router not found fallback" });

		if (to.name !== "root" && to.path === from.path)
			return next();

		if (to.name === "root" && AppStore.isPWASession && AppStore.defaultPWABaseRoute) {
			return nextRouter({
				name: "root-company-system",
				params: {
					...to.params,
					...AppStore.defaultPWABaseRoute
				},
				query: to.query
			});
		}

		if (to.name && (isSkippable(to, next) || isErrorPage(to))) {
			await LangStore.ensureLanguage();
			return next();
		}

		const basicData: Promise<any>[] = [];

		if ("companyId" in to.params) {
			const companyId = !isNaN(+to.params.companyId) ? +to.params.companyId : null;
			if (!companyId)
				return redirectToNotFound("companyId is NaN", from);

			await CompanyStore.ensureCompany(companyId);

			trackTrace({
				message: "Prompt: Company info loaded",
				severityLevel: SeverityLevel.Information
			});

			let systemId: number | null = null;

			if (!("systemId" in to.params)) {
				const { resolveSystem } = await import("@app-utilities/system");
				const resolvedSystem = await resolveSystem();

				if (!resolvedSystem || isNaN(resolvedSystem))
					return redirectToNotFound("cannot resolve system", from);

				systemId = resolvedSystem;
				if (!systemId)
					return redirectToNotFound("resolved systemId is falsy", from);

				const nextRoute = to.name && !to.name.startsWith("root") ? to.name : home;
				return next({
					name: nextRoute,
					query: to.query,
					params: { ...to.params, systemId: systemId + "" }
				});
			} else {
				systemId = !isNaN(Number(to.params.systemId)) ? Number(to.params.systemId) : null; // FIXME: vue router handles NaN check, maybe remove these checks
				if (!systemId)
					return redirectToNotFound("systemId is NaN", from);
			}
			const pair = { Id: systemId, CompanyId: companyId };
			const { ensureSystemCompanyPair } = await import("@app-utilities/system");
			const ensureCompanyPairResult = await ensureSystemCompanyPair(pair, true);

			if (!ensureCompanyPairResult.success) {
				const message = ensureCompanyPairResult.details;
				return redirectToNotFound(message, from);
			}

			if (!SystemStore.functionalities)
				return redirectToNotFound("Cannot retrieve functionalities", from);

			if (SystemStore.info) {
				const companyIdFromSystemInfo = SystemStore.CompanyId;
				const systemId = SystemStore.Id;
				if (!systemId || !companyIdFromSystemInfo)
					return redirectToNotFound("missing systemId or companyId from system store", from);

				if (companyId && companyIdFromSystemInfo && companyIdFromSystemInfo !== companyId)
					return redirectToNotFound("companyId param doesn't match companyId in system store", from);

				trackTrace({
					message: "Prompt: System info loaded",
					severityLevel: SeverityLevel.Information
				});

				const { ensureWebsiteData } = await import("@app-utilities/system");
				basicData.push(ensureWebsiteData());
				basicData.push((async() => {
					if (!AppStore.themeLoaded) {
						await AppStore.syncTheme({ companyId, systemId, previewUrl: (to.query.previewUrl ?? "") as string });
						AppStore.setThemeLoaded();

						trackTrace({
							message: "Prompt: Theme loaded",
							severityLevel: SeverityLevel.Information
						}, {
							...(to.query.previewUrl && { previewUrl: to.query.previewUrl })
						});

						// FIXME: NetworkInformation API is experimental and types are not available - update when API is stable (TS: #47495)
						const appReadyEventData: { networkInformation?: NetworkInformation } = {};
						const extendedNavigator = navigator as Navigator & { connection?: NetworkInformation };
						const navigatorConnection = extendedNavigator.connection;

						if (navigatorConnection) {
							appReadyEventData.networkInformation = {
								downlink: navigatorConnection.downlink ?? null,
								downlinkMax: navigatorConnection.downlinkMax ?? null,
								effectiveType: navigatorConnection.effectiveType ?? null,
								rtt: navigatorConnection.rtt ?? null,
								saveData: navigatorConnection.saveData ?? null,
								type: navigatorConnection.type ?? null
							};
						}

						await SystemStore.syncMaintenanceModeActive();
						trackUserEvent(EventAI.AppReady, appReadyEventData);
					}
					return Promise.resolve();
				})());
			}
		}

		await Promise.all(basicData);
		await LangStore.ensureLanguage();
		if (SystemStore.maintenanceMode && to.name !== maintenanceMode)
			return redirectToMaintenanceMode(nextRouter);

		let preventNext = false;
		if (isBookingPage(to)) {
			const { processBookingRoute } = await import("@app-store/reservation-flux");
			const result = await processBookingRoute(to, from);
			preventNext = result?.preventNext ?? false;
		}

		if (!preventNext)
			await next();
	});
